import React from "react";
import { Tooltip } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';
import "./QaColumn.css";


const QaColumn = ({ errors }) => {
  const renderTitle = err => {
    return err.map(item => <p key={item}>{item}</p>)
  };

  return (
    <>
      {
        errors?.length ?
          <Tooltip title={renderTitle(errors)}>
            <div className="error_icon_container">
              <ErrorOutlineIcon color="warning" fontSize="small" />
            </div>
          </Tooltip>
          :
          <CheckIcon color="success" fontSize="small" />
      }
    </>
  );
};

export default QaColumn;