import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import React from "react";
import "./Table.css";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { navTooltipTitle } from "../../constants";

const SearchPanel = ({ onSearch, searchValue, setSearchValue, noPadding = false }) => {

    const onSubmit = event => {
        event.preventDefault();
        onSearch();
    };

    const onInput = event => {
        setSearchValue(event.target.value);
    };

    const searchClassName = !noPadding ? "search_panel" : "search_panel p-0";

    return (
        <form className={searchClassName} onSubmit={onSubmit}>
            <MDInput
                placeholder="Search..."
                value={searchValue}
                onChange={onInput}
                size="small"
            />

            <MDButton className="search_button" variant="gradient" color="info" type="submit" size="small">
                search
            </MDButton>

            <Tooltip title={navTooltipTitle}>
                <IconButton>
                    <HelpOutlineIcon />
                </IconButton>
            </Tooltip>
        </form>
    );
};


export default SearchPanel;

