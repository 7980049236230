import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "layouts/pages/profile/components/Header";


function Account() {

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbsTitle="My account" />
      <MDBox mb={2} />
      <Header />
      <Footer />
    </DashboardLayout>
  );
}

export default Account;
