export const events = {
    "total": 340,
    "results": [
        {
            "id": 32616,
            "manuscript_id": "SS-2022-09-0439-OP",
            "article_id": 30761,
            "doi_id": "10.1055/ea-0605-0600",
            "source": "productionsystem",
            "type": "submitted",
            "created": "2022-09-07T21:06:59.710Z",
            "manuscript": "One-Step, Scalable Synthesis of Caffeine-d<sub>9</sub> from Xanthine and CD<sub>3</sub>I"
        },
        {
            "id": 32590,
            "manuscript_id": "E-Videos-2022-08-3440-EV",
            "article_id": 28542,
            "doi_id": null,
            "source": "CCC",
            "type": "accepted",
            "created": "2022-09-07T15:34:56.153Z",
            "manuscript": "Lariat preparation using a snare catheter for removal of a pancreaticogastric stent in a rendezvous stent exchange"
        },
        {
            "id": 32589,
            "manuscript_id": "IAORL-2022-06-1310-OR",
            "article_id": 30738,
            "doi_id": "10.1055/ea-0605-0600",
            "source": "productionsystem",
            "type": "revision",
            "created": "2022-09-07T15:33:48.415Z",
            "manuscript": "Temporal ordering and auditory resolution in individuals with sensorineural hearing loss"
        },
        {
            "id": 32588,
            "manuscript_id": "IAORL-2022-06-1307-SR",
            "article_id": 30737,
            "doi_id": null,
            "source": "s1-connector",
            "type": "revision",
            "created": "2022-09-07T15:31:30.994Z",
            "manuscript": "ELECTRICAL STIMULATION FOR TREATMENT OF DYSPHAGIA POST HEAD NECK CANCER:  A SYSTEMATIC REVIEW AND META-ANALYSIS"
        },
        {
            "id": 32587,
            "manuscript_id": "IAORL-2022-06-1313-OR",
            "article_id": 30736,
            "doi_id": "10.1055/ea-06304-0701",
            "source": "contentdistribution",
            "type": "revision",
            "created": "2022-09-05T15:30:49.919Z",
            "manuscript": "Study of clinical manifestations of wegener in Iranian ethnicities using K-means algorithm"
        },
        {
            "id": 32583,
            "manuscript_id": "E-Videos-2022-08-3480-EV",
            "article_id": 28997,
            "doi_id": "10.1055/ea-0334-1231",
            "source": "productionsystem",
            "type": "accepted",
            "created": "2022-09-07T15:18:53.129Z",
            "manuscript": "Underwater Endosonography (uEUS) for enhancement of Small Mucosal and Submucosal Gastrointestinal Lesions"
        },
        {
            "id": 32582,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "CCC",
            "type": "rejected",
            "created": "2022-03-03T15:18:38.719Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32581,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-3212",
            "source": "productionsystem",
            "type": "rejected",
            "created": "2022-01-11T15:18:38.638Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32580,
            "manuscript_id": "tcs-05-2022-6596-OC",
            "article_id": 18020,
            "doi_id": null,
            "source": "s1-connector",
            "type": "accepted",
            "created": "2022-09-09T14:57:59.245Z",
            "manuscript": "Omental flap for complex sternal wounds and mediastinal infection following cardiac surgery"
        },
        {
            "id": 32579,
            "manuscript_id": "PLAMED-2022-09-0631-OP",
            "article_id": 30732,
            "doi_id": null,
            "source": "CCC",
            "type": "submitted",
            "created": "2022-05-07T14:57:07.936Z",
            "manuscript": "In vivo anti-tumor effect of Atractylodes macrocephala Koidz and Zingiber prescriptions combined with paclitaxel in mice bearing ID8 ovarian carcinoma"
        },
        {
            "id": 32578,
            "manuscript_id": "JNLS-A-22-Sep-OA-0295",
            "article_id": 30731,
            "doi_id": "10.1055/s-0042-1744805",
            "source": "contentdistribution",
            "type": "submitted",
            "created": "2022-08-23T14:55:36.477Z",
            "manuscript": "Application of three different types of repair materials in cranioplasty"
        },
        {
            "id": 32577,
            "manuscript_id": "IJSM-08-2022-9709-gmb",
            "article_id": 21714,
            "doi_id": "10.1055/s-0042-1774803",
            "source": "contentdistribution",
            "type": "rejected",
            "created": "2021-03-19T14:47:07.530Z",
            "manuscript": "Exercise ameliorates diabetic cardiomyopathy via inhibiting VPO1/ERK1/2 signaling pathway"
        },
        {
            "id": 32583,
            "manuscript_id": "E-Videos-2022-08-3480-EV",
            "article_id": 28997,
            "doi_id": "10.1055/ea-0334-1231",
            "source": "eventstore",
            "type": "accepted",
            "created": "2022-09-07T15:18:53.129Z",
            "manuscript": "Underwater Endosonography (uEUS) for enhancement of Small Mucosal and Submucosal Gastrointestinal Lesions"
        },
        {
            "id": 32582,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "CCC",
            "type": "rejected",
            "created": "2022-03-03T15:18:38.719Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32581,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-3212",
            "source": "contentdistribution",
            "type": "rejected",
            "created": "2022-01-11T15:18:38.638Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32583,
            "manuscript_id": "E-Videos-2022-08-3480-EV",
            "article_id": 28997,
            "doi_id": "10.1055/ea-0334-1231",
            "source": "eventstore",
            "type": "accepted",
            "created": "2022-09-07T15:18:53.129Z",
            "manuscript": "Underwater Endosonography (uEUS) for enhancement of Small Mucosal and Submucosal Gastrointestinal Lesions"
        },
        {
            "id": 32582,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "CCC",
            "type": "rejected",
            "created": "2022-03-03T15:18:38.719Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32581,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-3212",
            "source": "CCC",
            "type": "rejected",
            "created": "2022-01-11T15:18:38.638Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32578,
            "manuscript_id": "JNLS-A-22-Sep-OA-0295",
            "article_id": 30731,
            "doi_id": "10.1055/s-0042-1744805",
            "source": "productionsystem",
            "type": "submitted",
            "created": "2022-08-23T14:55:36.477Z",
            "manuscript": "Application of three different types of repair materials in cranioplasty"
        },
        {
            "id": 32577,
            "manuscript_id": "IJSM-08-2022-9709-gmb",
            "article_id": 21714,
            "doi_id": "10.1055/s-0042-1774803",
            "source": "s1-connector",
            "type": "rejected",
            "created": "2021-03-19T14:47:07.530Z",
            "manuscript": "Exercise ameliorates diabetic cardiomyopathy via inhibiting VPO1/ERK1/2 signaling pathway"
        },
        {
            "id": 32583,
            "manuscript_id": "E-Videos-2022-08-3480-EV",
            "article_id": 28997,
            "doi_id": "10.1055/ea-0334-1231",
            "source": "productionsystem",
            "type": "accepted",
            "created": "2022-09-07T15:18:53.129Z",
            "manuscript": "Underwater Endosonography (uEUS) for enhancement of Small Mucosal and Submucosal Gastrointestinal Lesions"
        },
        {
            "id": 32582,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "CCC",
            "type": "rejected",
            "created": "2022-03-03T15:18:38.719Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32581,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-3212",
            "source": "productionsystem",
            "type": "rejected",
            "created": "2022-01-11T15:18:38.638Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32578,
            "manuscript_id": "JNLS-A-22-Sep-OA-0295",
            "article_id": 30731,
            "doi_id": "10.1055/s-0042-1744805",
            "source": "CCC",
            "type": "submitted",
            "created": "2022-08-23T14:55:36.477Z",
            "manuscript": "Application of three different types of repair materials in cranioplasty"
        },
        {
            "id": 32577,
            "manuscript_id": "IJSM-08-2022-9709-gmb",
            "article_id": 21714,
            "doi_id": "10.1055/s-0042-1774803",
            "source": "contentdistribution",
            "type": "rejected",
            "created": "2021-03-19T14:47:07.530Z",
            "manuscript": "Exercise ameliorates diabetic cardiomyopathy via inhibiting VPO1/ERK1/2 signaling pathway"
        },
        {
            "id": 32582,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "contentdistribution",
            "type": "rejected",
            "created": "2022-03-03T15:18:38.719Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32581,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-3212",
            "source": "contentdistribution",
            "type": "rejected",
            "created": "2022-01-11T15:18:38.638Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32578,
            "manuscript_id": "JNLS-A-22-Sep-OA-0295",
            "article_id": 30731,
            "doi_id": "10.1055/s-0042-1744805",
            "source": "eventstore",
            "type": "submitted",
            "created": "2022-08-23T14:55:36.477Z",
            "manuscript": "Application of three different types of repair materials in cranioplasty"
        },
        {
            "id": 32577,
            "manuscript_id": "IJSM-08-2022-9709-gmb",
            "article_id": 21714,
            "doi_id": "10.1055/s-0042-1774803",
            "source": "CCC",
            "type": "rejected",
            "created": "2021-03-19T14:47:07.530Z",
            "manuscript": "Exercise ameliorates diabetic cardiomyopathy via inhibiting VPO1/ERK1/2 signaling pathway"
        },
        {
            "id": 32583,
            "manuscript_id": "E-Videos-2022-08-3480-EV",
            "article_id": 28997,
            "doi_id": "10.1055/ea-0334-1231",
            "source": "CCC",
            "type": "accepted",
            "created": "2022-09-07T15:18:53.129Z",
            "manuscript": "Underwater Endosonography (uEUS) for enhancement of Small Mucosal and Submucosal Gastrointestinal Lesions"
        },
        {
            "id": 32582,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "contentdistribution",
            "type": "rejected",
            "created": "2022-03-03T15:18:38.719Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32582,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "CCC",
            "type": "rejected",
            "created": "2022-03-03T15:18:38.719Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32581,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-3212",
            "source": "eventstore",
            "type": "rejected",
            "created": "2022-01-11T15:18:38.638Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32578,
            "manuscript_id": "JNLS-A-22-Sep-OA-0295",
            "article_id": 30731,
            "doi_id": "10.1055/s-0042-1744805",
            "source": "eventstore",
            "type": "submitted",
            "created": "2022-08-23T14:55:36.477Z",
            "manuscript": "Application of three different types of repair materials in cranioplasty"
        },
        {
            "id": 32577,
            "manuscript_id": "IJSM-08-2022-9709-gmb",
            "article_id": 21714,
            "doi_id": "10.1055/s-0042-1774803",
            "source": "s1-connector",
            "type": "rejected",
            "created": "2021-03-19T14:47:07.530Z",
            "manuscript": "Exercise ameliorates diabetic cardiomyopathy via inhibiting VPO1/ERK1/2 signaling pathway"
        },
        {
            "id": 32583,
            "manuscript_id": "E-Videos-2022-08-3480-EV",
            "article_id": 28997,
            "doi_id": "10.1055/ea-0334-1231",
            "source": "contentdistribution",
            "type": "accepted",
            "created": "2022-09-07T15:18:53.129Z",
            "manuscript": "Underwater Endosonography (uEUS) for enhancement of Small Mucosal and Submucosal Gastrointestinal Lesions"
        },
        {
            "id": 32582,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "eventstore",
            "type": "rejected",
            "created": "2022-03-03T15:18:38.719Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32577,
            "manuscript_id": "IJSM-08-2022-9709-gmb",
            "article_id": 21714,
            "doi_id": "10.1055/s-0042-1774803",
            "source": "CCC",
            "type": "rejected",
            "created": "2021-03-19T14:47:07.530Z",
            "manuscript": "Exercise ameliorates diabetic cardiomyopathy via inhibiting VPO1/ERK1/2 signaling pathway"
        },
        {
            "id": 32583,
            "manuscript_id": "E-Videos-2022-08-3480-EV",
            "article_id": 28997,
            "doi_id": "10.1055/ea-0334-1231",
            "source": "productionsystem",
            "type": "accepted",
            "created": "2022-09-07T15:18:53.129Z",
            "manuscript": "Underwater Endosonography (uEUS) for enhancement of Small Mucosal and Submucosal Gastrointestinal Lesions"
        },
        {
            "id": 32582,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "productionsystem",
            "type": "rejected",
            "created": "2022-03-03T15:18:38.719Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32581,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-3212",
            "source": "CCC",
            "type": "rejected",
            "created": "2022-01-11T15:18:38.638Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series"
        },
        {
            "id": 32578,
            "manuscript_id": "JNLS-A-22-Sep-OA-0295",
            "article_id": 30731,
            "doi_id": "10.1055/s-0042-1744805",
            "source": "productionsystem",
            "type": "submitted",
            "created": "2022-08-23T14:55:36.477Z",
            "manuscript": "Application of three different types of repair materials in cranioplasty"
        },
        {
            "id": 32577,
            "manuscript_id": "IJSM-08-2022-9709-gmb",
            "article_id": 21714,
            "doi_id": "10.1055/s-0042-1774803",
            "source": "contentdistribution",
            "type": "rejected",
            "created": "2021-03-19T14:47:07.530Z",
            "manuscript": "Exercise ameliorates diabetic cardiomyopathy via inhibiting VPO1/ERK1/2 signaling pathway"
        },
        {
            "id": 32583,
            "manuscript_id": "E-Videos-2022-08-3480-EV",
            "article_id": 28997,
            "doi_id": "10.1055/ea-0334-1231",
            "source": "eventstore",
            "type": "accepted",
            "created": "2022-09-07T15:18:53.129Z",
            "manuscript": "Underwater Endosonography (uEUS) for enhancement of Small Mucosal and Submucosal Gastrointestinal Lesions"
        },
        {
            "id": 32577,
            "manuscript_id": "IJSM-08-2022-9709-gmb",
            "article_id": 21714,
            "doi_id": "10.1055/s-0042-1774803",
            "source": "s1-connector",
            "type": "rejected",
            "created": "2021-03-19T14:47:07.530Z",
            "manuscript": "Exercise ameliorates diabetic cardiomyopathy via inhibiting VPO1/ERK1/2 signaling pathway",
            "parsing_errors": ["instance.manuscript.submission_id is not of a type(s) integer"]
        },
        {
            "id": 32583,
            "manuscript_id": "E-Videos-2022-08-3480-EV",
            "article_id": 28997,
            "doi_id": "10.1055/ea-0334-1231",
            "source": "CCC",
            "type": "accepted",
            "created": "2022-09-07T15:18:53.129Z",
            "manuscript": "Underwater Endosonography (uEUS) for enhancement of Small Mucosal and Submucosal Gastrointestinal Lesions"
        },
        {
            "id": 32582,
            "manuscript_id": "EJPS-2022-08-6347-OA",
            "article_id": 19883,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "CCC",
            "type": "rejected",
            "created": "2022-03-03T15:18:38.719Z",
            "manuscript": "Fasciotens facilitates early abdominal wall closure in a pediatric population: a case series",
            "parsing_errors": ["instance.manuscript.submission_id is not of a type(s) integer"]
        },
        {
            "id": 32616,
            "manuscript_id": "SS-2022-09-0439-OP",
            "article_id": 30761,
            "doi_id": "10.1041/ea-6578-1257",
            "source": "contentdistribution",
            "type": "submitted",
            "created": "2022-09-07T21:06:59.710Z",
            "manuscript": "One-Step, Scalable Synthesis of Caffeine-d<sub>9</sub> from Xanthine and CD<sub>3</sub>I"
        },
        {
            "id": 32590,
            "manuscript_id": "E-Videos-2022-08-3440-EV",
            "article_id": 28542,
            "doi_id": "10.1041/ea-6578-8931",
            "source": "contentdistribution",
            "type": "accepted",
            "created": "2022-09-07T15:34:56.153Z",
            "manuscript": "Lariat preparation using a snare catheter for removal of a pancreaticogastric stent in a rendezvous stent exchange",
        },
    ]
}

export const eventsPerJournal = [
    {
        "journal": "Saccharum coarctatum",
        "doi": "10.1055/s-00034447",
        "submitted": "1",
        "review_initiated": "1",
        "inpeerreview": "0",
        "revision": "1",
        "accepted": "1",
        "rejected": "1",
        "withdrawn": "1",
        "transfered": "1",
        "status": "1",
        "transaction": "0",
        "production_entered": "1",
        "aam_published": "1",
        "published_online": "1",
        "issue_published": "1",
        "total": "10"
    },
    {
        "journal": "Euphorbia helleri Millsp.",
        "doi": "10.1055/s-00034923",
        "submitted": "1",
        "review_initiated": "1",
        "inpeerreview": "1",
        "revision": "0",
        "accepted": "1",
        "rejected": "1",
        "withdrawn": "1",
        "transfered": "1",
        "status": "1",
        "transaction": "0",
        "production_entered": "1",
        "aam_published": "1",
        "published_online": "0",
        "issue_published": "1",
        "total": "10"
    },
    {
        "journal": "Anisomeridium (Müll. Arg.) M. Choisy",
        "doi": "10.1055/s-00000003",
        "submitted": "1",
        "review_initiated": "1",
        "inpeerreview": "1",
        "revision": "0",
        "accepted": "1",
        "rejected": "1",
        "withdrawn": "1",
        "transfered": "0",
        "status": "1",
        "transaction": "1",
        "production_entered": "1",
        "aam_published": "1",
        "published_online": "0",
        "issue_published": "25",
        "total": "10"
    },
    {
        "journal": "Facelis Cass.",
        "doi": "10.1055/s-00022861",
        "submitted": "0",
        "review_initiated": "1",
        "inpeerreview": "1",
        "revision": "5",
        "accepted": "1",
        "rejected": "1",
        "withdrawn": "1",
        "transfered": "1",
        "status": "1",
        "transaction": "1",
        "production_entered": "5",
        "aam_published": "0",
        "published_online": "1",
        "issue_published": "29",
        "total": "10"
    },
    {
        "journal": "Carex mariposana L.H. Bailey ex Mack.",
        "doi": "10.1055/s-00000005",
        "submitted": "16",
        "review_initiated": "1",
        "inpeerreview": "1",
        "revision": "0",
        "accepted": "3",
        "rejected": "2",
        "withdrawn": "1",
        "transfered": "1",
        "status": "0",
        "transaction": "1",
        "production_entered": "4",
        "aam_published": "0",
        "published_online": "1",
        "issue_published": "29",
        "total": "10"
    },
    {
        "journal": "Sidalcea elegans Greene",
        "doi": "10.1055/s-00045735",
        "submitted": "1",
        "review_initiated": "0",
        "inpeerreview": "1",
        "revision": "1",
        "accepted": "1",
        "rejected": "0",
        "withdrawn": "1",
        "transfered": "1",
        "status": "1",
        "transaction": "0",
        "production_entered": "1",
        "aam_published": "1",
        "published_online": "1",
        "issue_published": "11",
        "total": "10"
    },
    {
        "journal": "Cardamine diphylla (Michx.) Alph. Wood",
        "doi": "10.1055/s-00000008",
        "submitted": "1",
        "review_initiated": "1",
        "inpeerreview": "1",
        "revision": "1",
        "accepted": "1",
        "rejected": "0",
        "withdrawn": "1",
        "transfered": "1",
        "status": "1",
        "transaction": "0",
        "production_entered": "1",
        "aam_published": "0",
        "published_online": "1",
        "issue_published": "1",
        "total": "10"
    },
    {
        "journal": "Glycine Willd.",
        "doi": "10.1055/s-00051909",
        "submitted": "1",
        "review_initiated": "0",
        "inpeerreview": "1",
        "revision": "1",
        "accepted": "1",
        "rejected": "1",
        "withdrawn": "1",
        "transfered": "1",
        "status": "1",
        "transaction": "1",
        "production_entered": "1",
        "aam_published": "0",
        "published_online": "1",
        "issue_published": "15",
        "total": "10"
    }
]