import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import PieGraph from "layouts/dashboards/sales/components/PieGraph";
import { getDashboardData } from "helpers/dashboard";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ArticleCountPanel from "components/ArticleCountPanel";


function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [articlesPerWeekChartData, setArticlesPerWeekChartData] = useState([]);
  const [publishedArticlesChartData, setPublishedArticlesChartData] = useState([]);
  const [transactionsChartData, setTransactionsChartData] = useState([]);
  const [events, setEvents] = useState([]);

  const formArticlesPerWeekChartData = data => {
    const acceptedData = data.accepted?.map(item => item.count);
    const submittedData = data.submitted?.map(item => item.count);
    const publishedData = data.published?.map(item => item.count);
    const weeks = data.published?.map(item => item.week);

    setArticlesPerWeekChartData({
      labels: weeks,
      datasets: [
        {
          label: "Accepted",
          color: "info",
          data: acceptedData,
        },
        {
          label: "Submitted",
          color: "dark",
          data: submittedData,
        },
        {
          label: "Published",
          color: "success",
          data: publishedData,
        },
      ],
    });
  };

  const formPublishedArticlesChartData = data => {
    const licenses = data.licenses?.map(item => item.license);
    const counts = data.licenses?.map(item => item.count);

    setPublishedArticlesChartData({
      labels: licenses,
      datasets: {
        backgroundColors: ["info", "dark", "success", "primary", "secondary", "warning", "error"],
        data: counts,
      }
    });
  };

  const formTransactionsChartData = data => {
    const weeks = data.transactions?.map(item => item.week);
    const counts = data.transactions?.map(item => item.count);

    setTransactionsChartData({
      labels: weeks,
      datasets: [{
        color: "dark",
        data: counts,
      }]
    });
  };

  const renderEvents = events => {
    const indexedEvents = events.map((item, index) => ({ id: index, ...item }));
    const lastElement = indexedEvents.find(item => item.id === 0);
    const penultimateElement = indexedEvents.find(item => item.id === 1) || null;
    const percentage = penultimateElement ? Math.round(((lastElement.count - penultimateElement.count) / penultimateElement.count) * 100) : null;

    return (
      <>
        <Grid item xs={6}>
          <MDBox lineHeight={1} display="flex" alignItems="baseline">
            <MDTypography variant="button"
              fontWeight="regular"
              color="secondary">
              this week: &nbsp;
            </MDTypography>

            <MDTypography variant="h5" fontWeight="bold">
              {lastElement.count}
            </MDTypography>
            {
              (percentage || percentage === 0) &&
              <MDTypography variant="button" fontWeight="bold" color={percentage >= 0 ? "success" : "error"}>
                &nbsp;{`(${percentage >= 0 ? "+" + percentage : percentage}%)`}
              </MDTypography>
            }
          </MDBox>
        </Grid>

        {
          penultimateElement &&
          <Grid item xs={6}>
            <MDBox lineHeight={1} display="flex" alignItems="baseline">
              <MDTypography variant="button"
                fontWeight="regular"
                color="secondary">
                last week: &nbsp;
              </MDTypography>

              <MDTypography variant="h5" fontWeight="bold">
                {penultimateElement.count}
              </MDTypography>
            </MDBox>
          </Grid>
        }
      </>
    );
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    getDashboardData()
      .then(res => {
        if (isMounted) {
          setData(res.data);
          formArticlesPerWeekChartData(res.data);
          formPublishedArticlesChartData(res.data);
          formTransactionsChartData(res.data);
          setEvents(res.data.events);
          setLoading(false);
        };
      })
      .catch(err => {
        setLoading(false);
        setError(true);
      })
    return () => { isMounted = false };
  }, []);


  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        {
          loading ?
            <Spinner />
            :
            error ?
              <ErrorMessage />
              :
              <>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Card>
                        <ArticleCountPanel
                          title={"Articles in pipeline"}
                          count={data.pipeline}
                        />
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Card>
                        <MDBox p={2}>
                          <MDBox mb={0.5} lineHeight={1}>
                            <MDTypography
                              variant="button"
                              fontWeight="medium"
                              color="text"
                              textTransform="capitalize"
                            >
                              Events per week
                            </MDTypography>
                          </MDBox>

                          <Grid container>
                            {
                              events.length ?
                                renderEvents(events)
                                :
                                <MDTypography variant="button" fontWeight="regular" color="secondary">
                                  No events per week
                                </MDTypography>
                            }
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                  </Grid>
                </MDBox>

                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                      <PieGraph
                        channelChartData={publishedArticlesChartData}
                        title={"Articles per license"}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                      <DefaultLineChart
                        title="Articles per week (last 10 weeks)"
                        description={
                          <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" ml={-1}>
                              <MDBadgeDot color="info" size="sm" badgeContent="Accepted" />
                              <MDBadgeDot color="dark" size="sm" badgeContent="Submitted" />
                              <MDBadgeDot color="success" size="sm" badgeContent="Published" />
                            </MDBox>
                          </MDBox>
                        }
                        chart={articlesPerWeekChartData}
                        xAxisTitle={"weeks"}
                      />
                    </Grid>
                  </Grid>
                </MDBox>

                <MDBox mb={3}>
                  <Grid item xs={12} md={12}>
                    <VerticalBarChart
                      title="Transactions per week"
                      chart={transactionsChartData}
                    />
                  </Grid>
                </MDBox>
              </>
        }
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};


export default Dashboard;
