import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "./handleErrors";
import { events, eventsPerJournal } from "./mocks/events";

export async function getEvents(offset, perPage, sortBy, sortDir) {
    // return axiosInstance
    //     .get(`tracker/events?startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: events });
};

export async function getEventsPerJournal(offset, perPage, sortBy, sortDir) {
    // return axiosInstance
    //     .get(`admin/events/journals?startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: eventsPerJournal });
};
