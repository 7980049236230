import React from "react";
import MDBox from "components/MDBox";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ReactDiffViewer from 'react-diff-viewer';
import Card from "@mui/material/Card";


function JsonTabContent({ loading, error, article }) {
  const stringifiedArticle = JSON.stringify(article, null, 11);

  return (
    <MDBox mb={3}>
      <MDBox mb={3}>
        {
          loading ?
            <Spinner />
            :
            error ?
              <ErrorMessage />
              :
              <Card>
                <MDBox mb={1} p={3} className="diff_viewer_container">
                  <ReactDiffViewer
                    oldValue={stringifiedArticle}
                    newValue={stringifiedArticle}
                    splitView={false}
                    hideLineNumbers={true}
                    showDiffOnly={false}
                  />
                </MDBox>
              </Card>
        }
      </MDBox>
    </MDBox>
  );
};


export default JsonTabContent;
