import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getArticles } from "helpers/articles";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import Pagination from "components/pagination/Pagination";
import { itemsPerPage, itemsTotal } from "../../constants";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import ExportPanel from "components/table/ExportPanel";
import { shortenTitle, transformDate, createMarkup } from "helpers/helpers";


function ArticlesList(props) {
  const navbarSearchValue = props.location.state?.searchValue || "";
  const memoizedPage = JSON.parse(sessionStorage.getItem("page")) || 0;
  const memoizedOffset = JSON.parse(sessionStorage.getItem("offset")) || 1;

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [articlesCount, setArticlesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(memoizedPage);
  const [offset, setOffset] = useState(memoizedOffset);
  const [sortBy, setSortBy] = useState("");
  const [sortDir, setSortDir] = useState("asc");
  const [searchValue, setSearchValue] = useState(navbarSearchValue);
  const [error, setError] = useState(false);


  const localSettings = JSON.parse(localStorage.getItem("thiemeSettings"));
  const sorting = localSettings?.sorting || "last event first";
  const startDate = localSettings?.startDate || "";
  const endDate = localSettings?.endDate || "";
  const scope = localSettings?.scope || "";
  const filteredScope = encodeURIComponent(JSON.stringify(scope)).replaceAll('%22', '');
  const filteredSorting = encodeURIComponent(JSON.stringify(sorting)).replaceAll('%22', '');

  const columns = React.useMemo(
    () => [
      {
        Header: 'manuscript id / DOI',
        id: 'manuscript_id/doi',
        Cell: ({ row }) =>
          <span className="route_condition_label">{`${row.original.manuscript_id || "-"} / ${row.original.doi || "-"}`}</span>
      },
      {
        Header: 'title',
        id: 'title',
        Cell: ({ row }) =>
          <span className="route_condition_label"
            dangerouslySetInnerHTML={createMarkup(shortenTitle(row.original.title || "-", 140))}
          />
      },
      {
        Header: 'Journal',
        id: 'journal',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.journal || "-"}</span>
      },
      {
        Header: 'Last event',
        id: 'last_event',
        Cell: ({ row }) =>
          <span className="route_condition_label">{`${transformDate(row.original.lastevent) || "-"} / ${row.original.lastevent_type || "-"}`}</span>
      },
    ], []
  );

  useEffect(() => {
    if (navbarSearchValue !== searchValue) {
      setCurrentPage(0);
      setOffset(1);
    };
    setSearchValue(navbarSearchValue);
  }, [navbarSearchValue]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getArticles(searchValue, offset, itemsPerPage, sortBy, sortDir, filteredSorting, startDate, endDate, filteredScope)
      .then(res => {
        if (isMounted) {
          setArticles(res.data.results);
          setArticlesCount(res.data.total)
          setPageCount(Math.ceil((res.data.total || itemsTotal) / itemsPerPage));
          setLoading(false);
        };
      })
      .catch(err => {
        setLoading(false);
        setError(true);
      })
    return () => { isMounted = false };
  }, [offset, sortBy, sortDir, searchValue, filteredSorting, startDate, endDate, filteredScope]);

  useEffect(() => {
    if (selectedMessage) {
      sessionStorage.setItem('page', currentPage);
      sessionStorage.setItem('offset', offset);
      props.history.push(`/articles/${selectedMessage}`);
    };
  }, [selectedMessage]);

  useEffect(() => {
    if (!selectedMessage) {
      sessionStorage.clear();
    }
  }, []);

  const handlePageClick = data => {
    const selectedPage = data.selected;
    const pageItems = selectedPage * itemsPerPage;
    setCurrentPage(selectedPage);
    setOffset(pageItems + 1);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar tableSearchValue={searchValue} />
      <MDBox my={3}>
        {
          loading ?
            <Spinner />
            :
            error ?
              <ErrorMessage />
              :
              <Card>
                <ExportPanel
                  articlesType="allArticles"
                  setLoading={setLoading}
                  setError={setError}
                  articlesCount={articlesCount}
                />
                {
                  articles.length ?
                    <>
                      <DataTable
                        data={articles}
                        columns={columns}
                        setSelectedMessage={setSelectedMessage}
                        param={"id"}
                        sortBy={sortBy}
                        sortDir={sortDir}
                        setSortBy={setSortBy}
                        setSortDir={setSortDir}
                        sortable={false}
                        hoverable
                        cursorPointer
                      />
                      <Pagination
                        handlePageClick={handlePageClick}
                        pageCount={pageCount}
                        currentPage={currentPage}
                      />
                    </>
                    :
                    <NoContentMessage message={"No articles"} />
                }
              </Card>
        }
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};


export default ArticlesList;
