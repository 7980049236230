import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


const DetailsSection = ({ id, sectionTitle, content }) => {
  
  return (
    <MDBox mb={3}>
      <Card id={id}>
        <MDBox mb={1} p={3}>
          <MDTypography variant="h5" fontWeight="medium">
            {sectionTitle}
          </MDTypography>
        </MDBox>
        {content}
      </Card>
    </MDBox>
  );
};

export default DetailsSection;