import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Spinner from 'components/Spinner/Spinner';
import JsonData from 'layouts/pages/details/settings/components/JsonData';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import MDButton from 'components/MDButton';
import { DialogTitle, Grid, Box } from '@mui/material';
import "./Modal.css";


function Modal({ isOpen, setIsOpen, loading, error, data, modalTitle, showRawData = false, content = null, size = "md", hideButtons = false }) {
    const [fullWidth, setFullWidth] = React.useState(true);

    const handleClose = () => {
        setIsOpen(false);
    };

    const filterRawData = data => {
        if (Array.isArray(data)) {
            return data.map(item => <JsonData key={item?.raw} data={item?.raw || "No raw data"} />)
        }
        return <JsonData data={data?.raw || "No raw data"} />
    };

    const filterJsonData = data => {
        if (Array.isArray(data)) {
            return data.map(item => { return { ...item, raw: undefined } })
        }
        return { ...data, raw: undefined }
    };

    return (
        <Dialog
            maxWidth={size}
            fullWidth={fullWidth}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
        >
            {
                !showRawData &&
                <DialogTitle id="alert-dialog-title" ml={3}>
                    {modalTitle}
                </DialogTitle>
            }

            <DialogContent>
                {
                    loading ?
                        <Spinner />
                        :
                        error ?
                            <ErrorMessage />
                            :
                            content ?
                                content
                                :
                                showRawData ?
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <DialogTitle id="alert-dialog-title" ml={3}>
                                                Raw data
                                            </DialogTitle>
                                            <Box className="json-container">
                                                {filterRawData(data)}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DialogTitle id="alert-dialog-title" ml={3}>
                                                JSON data
                                            </DialogTitle>
                                            <Box className="json-container">
                                                <JsonData data={filterJsonData(data)} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    :
                                    <JsonData data={data} />
                }
            </DialogContent>

            {
                !hideButtons &&
                <DialogActions>
                    <MDButton className="search_button" variant="gradient" color="info" size="medium" onClick={handleClose}>
                        Close
                    </MDButton>
                </DialogActions>
            }
        </Dialog>
    );
};


export default Modal;
