import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import Modal from "components/modal/Modal";
import { getAttribute } from "helpers/helpers";
import { getUsers } from "helpers/users";
import { itemsPerPage, itemsTotal } from "../../constants";
import Pagination from "components/pagination/Pagination";


function UsersList() {
  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [sortDir, setSortDir] = useState("asc");

  const columns = React.useMemo(
    () => [
      {
        Header: 'username',
        accessor: 'UserAttributes',
        id: 'username',
        Cell: ({ row }) =>
          <span className="route_condition_label">{getAttribute(row.original.Attributes, "name")}</span>
      },
      {
        Header: 'email',
        accessor: 'UserAttributes',
        id: 'email',
        Cell: ({ row }) =>
          <span className="route_condition_label">{getAttribute(row.original.Attributes, "email")}</span>
      },
    ], []
  );

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    getUsers(offset, itemsPerPage, sortBy, sortDir)
      .then(res => {
        if (isMounted) {
          setUsers(res.data.users)
          setPageCount(Math.ceil((res.data.total || itemsTotal) / itemsPerPage));
          setLoading(false);
        };
      })
      .catch(err => {
        setLoading(false);
        setError(true);
      })
    return () => { isMounted = false };
  }, [offset, sortBy, sortDir]);

  useEffect(() => {
    if (selectedMessage) {
      setIsOpen(true);
      setUserDetails(selectedMessage);
      setSelectedMessage(null);
    };
  }, [selectedMessage]);

  const handlePageClick = data => {
    const selectedPage = data.selected;
    const pageItems = selectedPage * itemsPerPage;
    setCurrentPage(selectedPage);
    setOffset(pageItems + 1);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={false}
        error={false}
        data={userDetails}
        modalTitle={"User details"}
      />

      <MDBox my={3}>
        {
          loading ?
            <Spinner />
            :
            error ?
              <ErrorMessage />
              :
              users.length ?
                <Card>
                  <DataTable
                    data={users}
                    columns={columns}
                    setSelectedMessage={setSelectedMessage}
                    param={"all"}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    setSortBy={setSortBy}
                    setSortDir={setSortDir}
                    hoverable
                    cursorPointer
                  />
                  <Pagination
                    handlePageClick={handlePageClick}
                    pageCount={pageCount}
                    currentPage={currentPage}
                  />
                </Card>
                :
                <NoContentMessage message={"No users"} />
        }
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};


export default UsersList;
