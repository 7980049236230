import React, { useState, useEffect } from "react";
import { Field } from 'formik';
import TextInput from "./TextInput";
import MDBox from "./MDBox";
import MDButton from "./MDButton";
import MDTypography from "./MDTypography";
import { Card, Grid } from "@mui/material";
import SpinnerSmall from "./Spinner/SpinnerSmall";


const IncompleteArticlesForm = ({ handleSubmit, isSubmitting, refreshList }) => {
    const [doiValue, setDoiValue] = useState("");
    const [manuscriptValue, setManuscriptValue] = useState("");

    useEffect(() => {
        setDoiValue("");
        setManuscriptValue("");
    }, [refreshList]);

    const showMergeBtn = !!(doiValue.trim() && manuscriptValue.trim());


    return (
        <Card>
            <MDBox bgColor="white" borderRadius="lg" p={3}>
                <MDTypography variant="h6" fontWeight="medium" mb={3}>
                    Enter manuscript id and DOI to merge
                </MDTypography>

                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                    <Grid container direction="column">
                        <MDBox mb={2}>
                            <Grid item xs={12} md={5}>
                                <Field
                                    onInput={e => setDoiValue(e.target.value)}
                                    component={TextInput}
                                    type="text"
                                    name="doi_id"
                                    label="DOI"
                                    variant="standard"
                                    fullWidth
                                    placeholder="DOI"
                                    InputLabelProps={{ shrink: true }} />
                            </Grid>
                        </MDBox>

                        <MDBox mb={2}>
                            <Grid item xs={12} md={5}>
                                <Field
                                    onInput={e => setManuscriptValue(e.target.value)}
                                    component={TextInput}
                                    type="text"
                                    name="manuscript_id"
                                    label="Manuscript id"
                                    variant="standard"
                                    fullWidth
                                    placeholder="Manuscript id"
                                    InputLabelProps={{ shrink: true }} />
                            </Grid>
                        </MDBox>

                        {
                            showMergeBtn ?
                                <MDBox mt={2} mb={1}>
                                    <MDButton variant="gradient" color="info" type="submit" disabled={isSubmitting}>
                                        {
                                            isSubmitting ?
                                                <SpinnerSmall />
                                                :
                                                "Merge"
                                        }
                                    </MDButton>
                                </MDBox>
                                :
                                null
                        }
                    </Grid>
                </MDBox>
            </MDBox>
        </Card>
    );
};


export default IncompleteArticlesForm;

