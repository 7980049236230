import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "./handleErrors";
import { users } from "./mocks/users";

export async function getUsers(offset, perPage, sortBy, sortDir) {
    // return axiosInstance
    //     .get(`admin/users?startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: users });
};
