import Dashboard from "layouts/dashboards/sales/Dashboard";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ArticlesList from "layouts/articles/ArticlesList";
import EventsList from "layouts/events/EventsList";
import Login from "layouts/authentication/log-in/Login";
import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";
import NoPidArticlesList from "layouts/articles/NoPidArticlesList";
import IncompleteArticlesList from "layouts/articles/IncompleteArticlesList";
import Account from "layouts/account/Account";
import ArticlesPerInstitutionList from "layouts/articles/ArticlesPerInstitutionList";
import EventsPerJournalList from "layouts/events/EventsPerJournalList";
import MDTypography from "components/MDTypography";
import ArticlesForConsortiaList from "layouts/articles/ArticlesForConsortiaList";


const routes = [
  {
    type: "collapse",
    name: "User",
    key: "user",
    icon: <MDAvatar alt="user image" size="sm" />,
    collapse: [
      {
        name: "My account",
        key: "account",
        route: "/account",
        component: <Account />,
      },
      {
        name: "Settings",
        key: "settings",
      },
      {
        name: "Log off",
        key: "logout",
        route: "/login",
        component: <Login />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "page",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "page",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    name: "Articles",
    key: "articles",
    route: "/articles",
    component: <ArticlesList />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Articles per institution",
        key: "articles-per-institution",
        route: "/articles-per-institution",
        component: <ArticlesPerInstitutionList />
      },
      {
        name: "Articles for consortia",
        key: "articles-for-consortia",
        route: "/articles-for-consortia",
        component: <ArticlesForConsortiaList />
      },
      {
        name: "Events per journal",
        key: "events-per-journal",
        route: "/events-per-journal",
        component: <EventsPerJournalList />
      },
    ],
  },
  {
    type: "collapse",
    name: "Workflows",
    key: "workflows",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Enrich PIDs",
        key: "no-pid-articles",
        route: "/no-pid-articles",
        component: <NoPidArticlesList />
      },
      {
        name: "Incomplete articles",
        key: "incomplete-articles",
        route: "/incomplete-articles",
        component: <IncompleteArticlesList />
      },
    ],
  },
  { type: "divider", key: "divider-11111" },
  { type: "title", title: "Admin", key: "title-admin" },
  {
    type: "page",
    icon: <MDTypography>U</MDTypography>,
    name: "Users",
    key: "users",
    route: "/users",
    component: <EditProduct />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Manage",
    key: "manage",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Journals",
        key: "journals",
        route: "/journals",
        component: <EditProduct />,
      }
    ],
  },
  {
    type: "page",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    name: "Events",
    key: "events",
    route: "/events",
    component: <EventsList />,
    noCollapse: true,
  },
];


export default routes;
