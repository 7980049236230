import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "./handleErrors";
import { journal, journals, journalsEvents } from "./mocks/journals";

export async function getJournals(sortBy, sortDir) {
    // return axiosInstance
    //     .get(`tracker/journals?orderby=${sortBy}&orderdir=${sortDir}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: journals });
};

export async function getJournal(id) {
    // return axiosInstance
    //     .get(`tracker/journal/${id}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: journal });
};

export async function getJournalsEvents() {
    // return axiosInstance
    //     .get(`tracker/journal/${id}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: journalsEvents });
};

