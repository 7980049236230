import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import DataTable from "components/table/Table";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import Modal from "components/modal/Modal";
import MDBox from "components/MDBox";
import { Switch } from "@mui/material";
import MDTypography from "components/MDTypography";
import { filterEvents } from "helpers/helpers";


function EventsTabContent({ data, columns, renderEmpty = false }) {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [showDisregardedEvents, setShowDisregardedEvents] = useState(false);

  useEffect(() => {
    setEvents(filterEvents(data, showDisregardedEvents));
  }, [data, showDisregardedEvents]);

  const findSelectedEvent = selectedMessage => {
    /// renderEmpty has demonstration purpose to render empty details
    if (renderEmpty) {
      return
    }
    return events.find(item => item.id === selectedMessage);
  };

  useEffect(() => {
    if (selectedMessage) {
      setIsOpen(true);
    };
  }, [selectedMessage]);


  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={false}
        error={false}
        data={findSelectedEvent(selectedMessage)}
        modalTitle={"Event details"}
        showRawData={true}
        size={"xl"}
      />

      {
        events.length ?
          <>
            <MDBox display="flex" alignItems="center" justifyContent="flex-end" mb={1}>
              <MDBox mt={0.5}>
                <Switch
                  checked={showDisregardedEvents}
                  onChange={() => setShowDisregardedEvents(!showDisregardedEvents)}
                />
              </MDBox>
              <MDBox ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Disregarded events
                </MDTypography>
              </MDBox>
            </MDBox>

            <Card>
              <DataTable
                data={events}
                columns={columns}
                setSelectedMessage={setSelectedMessage}
                param={"id"}
                setSortBy={() => { }}
                setSortDir={() => { }}
                hoverable
                cursorPointer
              />
            </Card>
          </>
          :
          <NoContentMessage message={"No events"} />
      }
    </>
  );
};


export default EventsTabContent;
