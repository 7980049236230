import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PieChart from "examples/Charts/PieChart";


function PieGraph({ channelChartData, title }) {

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6">{title}</MDTypography>
      </MDBox>

      <MDBox px={5}>
        <PieChart chart={channelChartData} />
      </MDBox>
    </Card>
  );
};

export default PieGraph;
