import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import Pagination from "components/pagination/Pagination";
import { itemsPerJournalEventsPage, itemsTotal } from "../../constants";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import { shortenTitle } from "helpers/helpers";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import { getEventsPerJournal } from "helpers/events";


function EventsPerJournalList() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [sortDir, setSortDir] = useState("asc");
  const [error, setError] = useState(false);

  const renderCell = itemCount => {
    return itemCount === "0" ?
      <StatusCell icon="close" color="error" status="" />
      :
      <StatusCell icon="done" color="success" status="" />
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'journal',
        id: 'journal',
        Cell: ({ row }) =>
          <span className="route_condition_label">{shortenTitle(row.original.journal, 50) || "-"}</span>
      },
      {
        Header: 'submitted',
        id: 'submitted',
        Cell: ({ row }) => renderCell(row.original.submitted)
      },
      {
        Header: 'review initiated',
        id: 'review_initiated',
        Cell: ({ row }) => renderCell(row.original.review_initiated)
      },
      {
        Header: 'in peer review',
        id: 'inpeerreview',
        Cell: ({ row }) => renderCell(row.original.inpeerreview)
      },
      {
        Header: 'revision',
        id: 'revision',
        Cell: ({ row }) => renderCell(row.original.revision)
      },
      {
        Header: 'accepted',
        id: 'accepted',
        Cell: ({ row }) => renderCell(row.original.accepted)
      },
      {
        Header: 'rejected',
        id: 'rejected',
        Cell: ({ row }) => renderCell(row.original.rejected)
      },
      {
        Header: 'withdrawn',
        id: 'withdrawn',
        Cell: ({ row }) => renderCell(row.original.withdrawn)
      },
      {
        Header: 'transfered',
        id: 'transfered',
        Cell: ({ row }) => renderCell(row.original.transfered)
      },
      {
        Header: 'status update',
        id: 'status',
        Cell: ({ row }) => renderCell(row.original.status)
      },
      {
        Header: 'transaction',
        id: 'transaction',
        Cell: ({ row }) => renderCell(row.original.transaction)
      },
      {
        Header: 'production entered',
        id: 'production_entered',
        Cell: ({ row }) => renderCell(row.original.production_entered)
      },
      {
        Header: 'aam published',
        id: 'aam_published',
        Cell: ({ row }) => renderCell(row.original.aam_published)
      },
      {
        Header: 'published online',
        id: 'published_online',
        Cell: ({ row }) => renderCell(row.original.published_online)
      },
      {
        Header: 'issue published',
        id: 'issue_published',
        Cell: ({ row }) => renderCell(row.original.issue_published)
      },
    ], []
  );

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    getEventsPerJournal(offset, itemsPerJournalEventsPage, sortBy, sortDir)
      .then(res => {
        if (isMounted) {
          setEvents(res.data);
          let total = itemsTotal
          if (res.data.length && res.data[0].total) {
            total = res.data[0].total
          }
          setPageCount(Math.ceil(total / itemsPerJournalEventsPage));
          setLoading(false);
        };
      })
      .catch(err => {
        setLoading(false);
        setError(true);
      })
    return () => { isMounted = false };
  }, [offset, sortBy, sortDir]);

  const handlePageClick = data => {
    const selectedPage = data.selected;
    const pageItems = selectedPage * itemsPerJournalEventsPage;
    setCurrentPage(selectedPage);
    setOffset(pageItems + 1);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbsTitle="Events per journal" />

      <MDBox my={3}>
        {
          loading ?
            <Spinner />
            :
            error ?
              <ErrorMessage />
              :
              events.length ?
                <Card>
                  <DataTable
                    data={events}
                    columns={columns}
                    setSelectedMessage={() => { }}
                    param={"article_id"}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    setSortBy={setSortBy}
                    setSortDir={setSortDir}
                  />
                  <Pagination
                    handlePageClick={handlePageClick}
                    pageCount={pageCount}
                    currentPage={currentPage}
                  />
                </Card>
                :
                <NoContentMessage message={"No events"} />
        }
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};


export default EventsPerJournalList;
