import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getNoPidArticles, getArticle } from "helpers/articles";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import Pagination from "components/pagination/Pagination";
import { itemsPerPage, itemsTotal } from "../../constants";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import Modal from "components/modal/Modal";
import ExportPanel from "components/table/ExportPanel";


function NoPidArticlesList() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [sortDir, setSortDir] = useState("asc");
  const [error, setError] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [eventArticle, setEventArticle] = useState(null);
  const [modalError, setModalError] = useState(false);

  const localSettings = JSON.parse(localStorage.getItem("thiemeSettings"));
  const sorting = localSettings?.sorting || "last event first";
  const startDate = localSettings?.startDate || "";
  const endDate = localSettings?.endDate || "";
  const scope = localSettings?.scope || "";
  const filteredScope = encodeURIComponent(JSON.stringify(scope));
  const filteredSorting = encodeURIComponent(JSON.stringify(sorting));

  const renderInstitutions = item => {
    return item.institutions.length ?
      item.institutions.map(item => item.name ? item.name : null).join(', ')
      :
      null;
  };

  const renderAuthors = authors => {
    return authors.length ?
      authors.map(item => item.institutions ? renderInstitutions(item) : null)
      :
      "-";
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'manuscript id',
        id: 'manuscript_id',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.manuscript_id || "-"}</span>
      },
      {
        Header: 'No PID',
        id: 'authors',
        Cell: ({ row }) =>
          <span className="route_condition_label">{renderAuthors(row.original.authors) || []}</span>
      },
    ], []
  );


  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    getNoPidArticles(offset, itemsPerPage, sortBy, sortDir, filteredSorting, startDate, endDate, filteredScope)
      .then(res => {
        if (isMounted) {
          setArticles(res.data.results);
          setPageCount(Math.ceil((res.data.total || itemsTotal) / itemsPerPage));
          setLoading(false);
        };
      })
      .catch(err => {
        setLoading(false);
        setError(true);
      })
    return () => { isMounted = false };
  }, [offset, sortBy, sortDir, startDate, endDate, filteredScope, filteredSorting]);

  useEffect(() => {
    if (selectedMessage) {
      let isMounted = true;
      setIsOpen(true);
      setModalLoading(true);

      getArticle(selectedMessage)
        .then(res => {
          if (isMounted) {
            setEventArticle(res.data.article);
            setSelectedMessage(null);
            setModalLoading(false);
          };
        })
        .catch(error => {
          setModalLoading(false);
          setModalError(true);
        })
      return () => { isMounted = false };
    };
    return null;
  }, [selectedMessage]);

  const handlePageClick = data => {
    const selectedPage = data.selected;
    const pageItems = selectedPage * itemsPerPage;
    setCurrentPage(selectedPage);
    setOffset(pageItems + 1);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbsTitle="Enrich PIDs" />

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={modalLoading}
        error={modalError}
        data={eventArticle}
        modalTitle={"Article details"}
      />

      <MDBox my={3}>
        {
          loading ?
            <Spinner />
            :
            error ?
              <ErrorMessage />
              :
              <Card>
                <ExportPanel
                  articlesType="noPidArticles"
                  setLoading={setLoading}
                  setError={setError}
                />
                {
                  articles.length ?
                    <>
                      <DataTable
                        data={articles}
                        columns={columns}
                        setSelectedMessage={setSelectedMessage}
                        param={"id"}
                        sortBy={sortBy}
                        sortDir={sortDir}
                        setSortBy={setSortBy}
                        setSortDir={setSortDir}
                        hoverable
                        cursorPointer
                      />
                      <Pagination
                        handlePageClick={handlePageClick}
                        pageCount={pageCount}
                        currentPage={currentPage}
                      />
                    </>
                    :
                    <NoContentMessage message={"No articles without PID"} />
                }
              </Card>
        }
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};


export default NoPidArticlesList;
