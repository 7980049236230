export const journals = {
    "total": 10,
    "results": [
        {
            "id": 232,
            "doi": "10.1055/s-00034447",
            "status": "publishing",
            "title": "Saccharum coarctatum",
            "issn_p": "",
            "issn_p_supp": "2566-9346",
            "issn_e": "",
            "issn_e_supp": "",
            "oa": "true",
            "s1": "aciopen",
            "em": null
        },
        {
            "id": 259,
            "doi": "10.1055/s-00039246",
            "status": "publishing",
            "title": "Euphorbia helleri Millsp.",
            "issn_p": "0007-0785",
            "issn_p_supp": "0007-0785",
            "issn_e": "",
            "issn_e_supp": "",
            "oa": "false",
            "s1": null,
            "em": null
        },
        {
            "id": 45,
            "doi": "10.1055/s-00000049",
            "status": "publishing",
            "title": "Anisomeridium (Müll. Arg.) M. Choisy",
            "issn_p": "1531-0043",
            "issn_p_supp": "1530-9681",
            "issn_e": "",
            "issn_e_supp": "",
            "oa": "false",
            "s1": null,
            "em": null
        },
        {
            "id": 296,
            "doi": "10.1055/s-00046370",
            "status": "publishing",
            "title": "Facelis Cass.",
            "issn_p": "",
            "issn_p_supp": "2699-9404",
            "issn_e": "",
            "issn_e_supp": "",
            "oa": "true",
            "s1": null,
            "em": "gmg"
        },
        {
            "id": 197,
            "doi": "10.1055/s-00028728",
            "status": "publishing",
            "title": "Carex mariposana L.H. Bailey ex Mack.",
            "issn_p": "2277-954X",
            "issn_p_supp": "2277-9167",
            "issn_e": "",
            "issn_e_supp": "",
            "oa": "true",
            "s1": null,
            "em": "ijns"
        },
        {
            "id": 208,
            "doi": "10.1055/s-00029202",
            "status": "publishing",
            "title": "Sidalcea elegans Greene",
            "issn_p": "0973-0508",
            "issn_p_supp": "2213-3739",
            "issn_e": "",
            "issn_e_supp": "",
            "oa": "false",
            "s1": null,
            "em": null
        },
        {
            "id": 162,
            "doi": "10.1055/s-00000181",
            "status": "publishing",
            "title": "Cardamine diphylla (Michx.) Alph. Wood",
            "issn_p": "2193-6331",
            "issn_p_supp": "2193-634X",
            "issn_e": "",
            "issn_e_supp": "",
            "oa": "false",
            "s1": null,
            "em": null
        },
        {
            "id": 32,
            "doi": "10.1055/s-00000032",
            "status": "publishing",
            "title": "Glycine Willd.",
            "issn_p": "1434-0275",
            "issn_p_supp": "1439-4081",
            "issn_e": "2509-9388",
            "issn_e_supp": "1615-3316",
            "oa": "false",
            "s1": null,
            "em": null
        },
        {
            "id": 240,
            "doi": "10.1055/s-00034916",
            "status": "publishing",
            "title": "Phacelia novenmillensis Munz",
            "issn_p": "0722-1541",
            "issn_p_supp": "2567-5788",
            "issn_e": "",
            "issn_e_supp": "",
            "oa": "false",
            "s1": null,
            "em": null
        },
        {
            "id": 121,
            "doi": "10.1055/s-00000140",
            "status": "publishing",
            "title": "Vicia hirsuta (L.) Gray",
            "issn_p": "1611-6445",
            "issn_p_supp": "1862-9393",
            "issn_e": "",
            "issn_e_supp": "",
            "oa": "false",
            "s1": null,
            "em": null
        }
    ]
}

export const journal = {
    "journal": {
        "id": 259,
        "doi": "10.1055/s-00039246",
        "status": "publishing",
        "title": "Sidalcea elegans Greene",
        "issn_p": "0007-0123",
        "issn_p_supp": "0007-0123",
        "issn_e": "",
        "issn_e_supp": "",
        "oa": "false",
        "s1": null,
        "em": null
    }
}

export const journalsEvents = [
    {
        event: 'Submitted',
        article: `{
            "id": 1,
            "manuscript": {
                "manuscript_id": "568-8723232ase",
                "title": "Research in Pandemic decision-making is difficult and exhausting – here’s the psychology that explains why",
                "morelanguages": null,
                "journal_doi": "10.1055/s-00000049",
                "doi": null,
            },
            "authors": [
                {
                    "firstname": "Dalia",
                    "lastname": "Bushen",
                    "orcid": "http://orchid.org/4809434--23-2328",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Ministry of Agriculture, Climate Change and Environment"
                        }
                    ],
                    "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                }
            ]
        }`
    },

    {
        event: 'Accepted',
        article: `{
            "id": 2,
            "manuscript": {
                "manuscript_id": "568-8723232ase",
                "title": "Research in Pandemic decision-making is difficult and exhausting – here’s the psychology that explains why",
                "morelanguages": null,
                "dates": {
                    "accepted": "2021-03-30"
                },
                "journal_doi": "10.1055/s-00000049",
                "doi": "10.1055/s-0037-1604247",
            },
            "authors": [
                {
                    "firstname": "Osgood",
                    "lastname": "Millhouse",
                    "orcid": "http://orchid.org/4809434--23-2327",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Leiden University",
                            "ror_id": "https://ror.org/027bh9e22"
                        }
                    ],
                    "affiliation": "Leiden University"
                },
                {
                    "firstname": "Dalia",
                    "lastname": "Bushen",
                    "orcid": "http://orchid.org/4809434--23-2328",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Ministry of Agriculture, Climate Change and Environment"
                        }
                    ],
                    "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                }
            ]
        }`
    },

    {
        event: 'Production',
        article: `{
            "id": 3,
            "manuscript": {
                "manuscript_id": "568-8723232ase",
                "title": "Discoveries in Pandemic decision-making are easy and pleasant",
                "morelanguages": null,
                "dates": {
                    "accepted": "2021-03-30"
                },
                "issue": {
                    "volume": "30",
                    "issue": "04",
                    "page": "223-224"
                },
                "journal_doi": "10.1055/s-00000049",
                "doi": "10.1055/s-0037-1604247",
            },
            "issue": {
                "volume": "30",
                "issue": "04",
                "pages": "223-224"
            },
            "authors": [
                {
                    "firstname": "Adoree",
                    "lastname": "Berrisford",
                    "orcid": "http://orchid.org/4809434--23-2326",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": true,
                    "institutions": [
                        {
                            "name": "University of Oxford",
                            "ror_id": "https://ror.org/052gg0110"
                        },
                    ],
                    "affiliation": "University of Oxford,Nekton"
                },
                {
                    "firstname": "Osgood",
                    "lastname": "Millhouse",
                    "orcid": "http://orchid.org/4809434--23-2327",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Leiden University",
                            "ror_id": "https://ror.org/027bh9e22"
                        }
                    ],
                    "affiliation": "Leiden University"
                },
                {
                    "firstname": "Dalia",
                    "lastname": "Bushen",
                    "orcid": "http://orchid.org/4809434--23-2328",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Ministry of Agriculture, Climate Change and Environment"
                        }
                    ],
                    "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                }
            ]
        }`
    },

    {
        event: 'Aam published',
        article: `{
            "id": 4,
            "manuscript": {
                "manuscript_id": "568-8723232ase",
                "title": "Discoveries in Pandemic decision-making are easy and pleasant",
                "morelanguages": null,
                "license": "non-CC BY",
                "dates": {
                    "published": {
                        "print": "2021-03-15",
                        "issue": "2017-09-01",
                        "aam": "2021-04-11",
                        "online": null
                    },
                    "accepted": "2021-03-30"
                },
                "issue": {
                    "volume": "30",
                    "issue": "04",
                    "page": "223-224"
                },
                "journal_doi": "10.1055/s-00000049",
                "doi": "10.1055/s-0037-1604247",
            },
            "issue": {
                "volume": "30",
                "issue": "04",
                "pages": "223-224"
            },
            "authors": [
                {
                    "firstname": "Sherlock",
                    "lastname": "Paolillo",
                    "orcid": "http://orchid.org/4809434--23-2324",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": true,
                    "institutions": [
                        {
                            "name": "Nekton",
                            "ror_id": "https://ror.org/02zrwga81"
                        },
                        {
                            "name": "Ministry of Agriculture, Climate Change and Environment",
                            "ror_id": "https://ror.org/00fyzzw59"
                        }
                    ],
                    "affiliation": "Nekton,Ministry of Agriculture, Climate Change and Environment"
                },
                {
                    "firstname": "Nikolaus",
                    "lastname": "Clarke",
                    "orcid": "http://orchid.org/4809434--23-2325",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Nekton"
                        }
                    ],
                    "affiliation": "Nekton"
                },
                {
                    "firstname": "Adoree",
                    "lastname": "Berrisford",
                    "orcid": "http://orchid.org/4809434--23-2326",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": true,
                    "institutions": [
                        {
                            "name": "University of Oxford",
                            "ror_id": "https://ror.org/052gg0110"
                        },
                    ],
                    "affiliation": "University of Oxford,Nekton"
                },
                {
                    "firstname": "Osgood",
                    "lastname": "Millhouse",
                    "orcid": "http://orchid.org/4809434--23-2327",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Leiden University",
                            "ror_id": "https://ror.org/027bh9e22"
                        }
                    ],
                    "affiliation": "Leiden University"
                },
                {
                    "firstname": "Dalia",
                    "lastname": "Bushen",
                    "orcid": "http://orchid.org/4809434--23-2328",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Ministry of Agriculture, Climate Change and Environment"
                        }
                    ],
                    "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                }
            ]
        }`
    },

    {
        event: 'Published online',
        article: `{
            "id": 5,
            "manuscript": {
                "manuscript_id": "568-8723232ase",
                "title": "Discoveries in Pandemic decision-making are easy and pleasant",
                "language": "en",
                "morelanguages": null,
                "license": "non-CC BY",
                "dates": {
                    "received": "2020-09-28",
                    "published": {
                        "print": "2021-03-15",
                        "issue": "2017-09-01",
                        "aam": "2021-04-11",
                        "online": "2021-04-12"
                    },
                    "accepted": "2021-03-30"
                },
                "issue": {
                    "volume": "30",
                    "issue": "04",
                    "page": "223-224"
                },
                "journal_doi": "10.1055/s-00000049",
                "doi": "10.1055/s-0037-1604247",
                "type": "research-article"
            },
            "issue": {
                "volume": "30",
                "issue": "04",
                "pages": "223-224"
            },
            "authors": [
                {
                    "firstname": "Sharona",
                    "lastname": "Montes",
                    "orcid": "http://orchid.org/4809434--23-2323",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": true,
                    "institutions": [
                        {
                            "name": "University of Oxford",
                            "ror_id": "https://ror.org/052gg0110"
                        },
                        {
                            "name": "Nekton",
                            "ror_id": "https://ror.org/02zrwga81"
                        }
                    ],
                    "affiliation": "University of Oxford,Nekton"
                },
                {
                    "firstname": "Sherlock",
                    "lastname": "Paolillo",
                    "orcid": "http://orchid.org/4809434--23-2324",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": true,
                    "institutions": [
                        {
                            "name": "Nekton",
                            "ror_id": "https://ror.org/02zrwga81"
                        },
                        {
                            "name": "Ministry of Agriculture, Climate Change and Environment",
                            "ror_id": "https://ror.org/00fyzzw59"
                        }
                    ],
                    "affiliation": "Nekton,Ministry of Agriculture, Climate Change and Environment"
                },
                {
                    "firstname": "Nikolaus",
                    "lastname": "Clarke",
                    "orcid": "http://orchid.org/4809434--23-2325",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Nekton"
                        }
                    ],
                    "affiliation": "Nekton"
                },
                {
                    "firstname": "Adoree",
                    "lastname": "Berrisford",
                    "orcid": "http://orchid.org/4809434--23-2326",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": true,
                    "institutions": [
                        {
                            "name": "University of Oxford",
                            "ror_id": "https://ror.org/052gg0110"
                        },
                    ],
                    "affiliation": "University of Oxford,Nekton"
                },
                {
                    "firstname": "Osgood",
                    "lastname": "Millhouse",
                    "orcid": "http://orchid.org/4809434--23-2327",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Leiden University",
                            "ror_id": "https://ror.org/027bh9e22"
                        }
                    ],
                    "affiliation": "Leiden University"
                },
                {
                    "firstname": "Dalia",
                    "lastname": "Bushen",
                    "orcid": "http://orchid.org/4809434--23-2328",
                    "email": "JohnDoe@gmail.com",
                    "iscorresponding": false,
                    "institutions": [
                        {
                            "name": "Ministry of Agriculture, Climate Change and Environment"
                        }
                    ],
                    "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                }
            ]
        }`
    },
]
