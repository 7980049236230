import React from "react";
import { Field } from 'formik';
import TextInput from "./TextInput";
import MDBox from "./MDBox";
import MDButton from "./MDButton";

const LoginForm = ({ handleSubmit }) => (
    <MDBox component="form" role="form" onSubmit={handleSubmit}>
        <MDBox mb={2}>
            <Field
                component={TextInput}
                type="email"
                name="email"
                label="Email"
                variant="standard"
                fullWidth
                placeholder="Email"
                InputLabelProps={{ shrink: true }} />
        </MDBox>
        <MDBox mb={2}>
            <Field
                component={TextInput}
                type="password"
                name="password"
                label="Password"
                variant="standard"
                fullWidth
                placeholder="Password"
                InputLabelProps={{ shrink: true }} />
        </MDBox>

        <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth type="submit">
                login
            </MDButton>
        </MDBox>
    </MDBox>
);

export default LoginForm;

