import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import Pagination from "components/pagination/Pagination";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import ArticleCountPanel from "components/ArticleCountPanel";
import PieGraph from "layouts/dashboards/sales/components/PieGraph";
import MDBadgeDot from "components/MDBadgeDot";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { itemsPerPage, itemsTotal } from "../../constants";
import { Autocomplete } from "@mui/material";
import { getArticlesForInstitution, getDataForInstitution } from "helpers/articles";
import { shortenTitle, transformDate, createMarkup } from "helpers/helpers";
import Grid from "@mui/material/Grid";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";

function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

function ArticlesPerInstitutionList(props) {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [sortDir, setSortDir] = useState("asc");
  const [error, setError] = useState(false);
  const [value, setValue] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [publishedArticlesChartData, setPublishedArticlesChartData] = useState([]);
  const [articlesPerMonthChartData, setArticlesPerMonthChartData] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'manuscript id / DOI',
        id: 'manuscript_id/doi',
        Cell: ({ row }) =>
          <span className="route_condition_label">{`${row.original.manuscript_id || "-"} / ${row.original.doi || "-"}`}</span>
      },
      {
        Header: 'title',
        id: 'title',
        Cell: ({ row }) =>
          <span className="route_condition_label"
            dangerouslySetInnerHTML={createMarkup(shortenTitle(row.original.title || "-", 140))}
          />
      },
      {
        Header: 'Journal',
        id: 'journal',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.journal || "-"}</span>
      },
      {
        Header: 'Last event',
        id: 'last_event',
        Cell: ({ row }) =>
          <span className="route_condition_label">{`${transformDate(row.original.lastevent) || "-"} / ${row.original.lastevent_type || "-"}`}</span>
      },
    ], []
  );

  const handlePageClick = data => {
    const selectedPage = data.selected;
    const pageItems = selectedPage * itemsPerPage;
    setCurrentPage(selectedPage);
    setOffset(pageItems + 1);
  };

  const loadRorOptions = async (inputValue) => {
    const value = inputValue.trim();
    let items = [];

    if (value.length > 2) {
      const result = await fetch(`https://api.ror.org/organizations?query=${encodeURIComponent(value)}`);
      const data = await result.json();

      items = data.items.map(item => {
        return {
          value: item.id,
          title: item.name,
        };
      });

      setOptions(items);
    };
  };

  const formPublishedArticlesChartData = data => {
    const journals = data.journals?.map(item => item.title);
    const counts = data.journals?.map(item => item.count);

    setPublishedArticlesChartData({
      labels: journals,
      datasets: {
        backgroundColors: ["info", "dark", "success", "primary", "secondary", "warning", "error"],
        data: counts,
      }
    });
  };

  const getMonthName = (monthNumber) => {
    const date = new Date()
    date.setMonth(monthNumber - 1)
    return date.toLocaleString('default', { month: 'short' }).toLocaleLowerCase()
  }

  const formArticlesPerMonthChartData = data => {
    const acceptedData = data.accepted?.map((item) => item.count);
    const submittedData = data.submited?.map((item) => item.count);
    const publishedData = data.published?.map((item) => item.count);
    const months =
      data.published?.map((item) => `${item.year.slice(2,4)}-${getMonthName(item.month)}`) ||
      data.accepted?.map((item) => `${item.year.slice(2,4)}-${getMonthName(item.month)}`) ||
      data.submited?.map((item) => `${item.year.slice(2,4)}-${getMonthName(item.month)}`);

    setArticlesPerMonthChartData({
      labels: months,
      datasets: [
        {
          label: "Accepted",
          color: "info",
          data: acceptedData,
        },
        {
          label: "Submitted",
          color: "dark",
          data: submittedData,
        },
        {
          label: "Published",
          color: "success",
          data: publishedData,
        },
      ],
    });
  };

  useEffect(() => {
    loadRorOptions(value);
  }, [value]);

  useEffect(() => {
    if (selectedItem) {
      let isMounted = true;
      setLoading(true);
      const filteredRor = encodeURIComponent(selectedItem.value);

      getArticlesForInstitution(filteredRor, offset, itemsPerPage, sortBy, sortDir)
        .then(res => {
          if (isMounted) {
            setArticles(res.data.articles);
            setPageCount(Math.ceil((res.data.total || itemsTotal) / itemsPerPage));
            setLoading(false);
          };
        })
        .catch(err => {
          setLoading(false);
          setError(true);
        })
      return () => { isMounted = false };
    };
  }, [selectedItem, offset, itemsPerPage, sortBy, sortDir]);

  useEffect(() => {
    if (selectedItem) {
      let isMounted = true;
      setLoading(true);
      const filteredRor = encodeURIComponent(selectedItem.value);

      getDataForInstitution(filteredRor)
        .then(res => {
          if (isMounted) {
            setData(res.data);
            formPublishedArticlesChartData(res.data);
            formArticlesPerMonthChartData(res.data);
            setLoading(false);
          };
        })
        .catch(err => {
          setLoading(false);
          setError(true);
        })
      return () => { isMounted = false };
    };
  }, [selectedItem]);

  useEffect(() => {
    if (selectedMessage) {
      props.history.push(`/articles/${selectedMessage}`);
    };
  }, [selectedMessage]);


  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbsTitle="Articles per institution" />

      <MDBox my={3}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <ErrorMessage />
        ) : (
          <>
            <MDBox mb={3}>
              <Card>
                <MDBox className="search_panel">
                  <Autocomplete
                    className="async_autocomplete"
                    filterOptions={(x) => x}
                    options={options}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option) => <li {...props}>{option.title}</li>}
                    onChange={(event, newValue) => setSelectedItem(newValue)}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        onChange={(event) => setValue(event.target.value)}
                        variant="standard"
                        placeholder="Type to search institution"
                      />
                    )}
                  />
                </MDBox>
                {selectedItem?.title && (
                  <MDBox p={3}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Report for institution {selectedItem.title}
                    </MDTypography>
                  </MDBox>
                )}
              </Card>
            </MDBox>

            {selectedItem && (
              <>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Card>
                        <ArticleCountPanel title={"Unpublished articles"} count={data.pipeline} />
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Card>
                        <Grid container>
                          {data.published_per_year && data.published_per_year.length ? (
                            data.published_per_year
                              .filter((item) => item.year !== 2100)
                              .map((item, i) => {
                                let percentage = null;
                                if (i === 0) {
                                  const penultimateElement = data.published_per_year[i + 1];
                                  percentage = Math.round(
                                    ((item.count - penultimateElement.count) /
                                      (penultimateElement.count ? penultimateElement.count : 1)) *
                                      100
                                  );
                                }
                                return (
                                  <Grid item xs={12} md={6} key={item.year}>
                                    <ArticleCountPanel
                                      title={item.year}
                                      count={item.count}
                                      percentage={percentage}
                                    />
                                  </Grid>
                                );
                              })
                          ) : (
                            <ArticleCountPanel title={"Published articles"} count={null} />
                          )}
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </MDBox>

                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                      <PieGraph
                        channelChartData={publishedArticlesChartData}
                        title={"Published articles per journal"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={8}>
                      <DefaultLineChart
                        title="Articles per month"
                        description={
                          <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" ml={-1}>
                              <MDBadgeDot color="info" size="sm" badgeContent="Accepted" />
                              <MDBadgeDot color="dark" size="sm" badgeContent="Submitted" />
                              <MDBadgeDot color="success" size="sm" badgeContent="Published" />
                            </MDBox>
                          </MDBox>
                        }
                        chart={articlesPerMonthChartData}
                        xAxisTitle={"month"}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </>
            )}

            <Card>
              {articles.length ? (
                <>
                  <DataTable
                    data={articles}
                    columns={columns}
                    setSelectedMessage={setSelectedMessage}
                    param={"id"}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    setSortBy={setSortBy}
                    setSortDir={setSortDir}
                    hoverable
                    cursorPointer
                  />
                  <Pagination
                    handlePageClick={handlePageClick}
                    pageCount={pageCount}
                    currentPage={currentPage}
                  />
                </>
              ) : (
                <NoContentMessage message={"No articles"} />
              )}
            </Card>
          </>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};


export default ArticlesPerInstitutionList;