import axios from 'axios';
import { token as TOKEN, URL, AUTH_URL } from '../constants';

const configureAxiosInstances = (urls) => (
    urls.map((url) => {
        const axiosInstance = axios.create({
            baseURL: url,
        })
        axiosInstance.interceptors.request.use((config) => {
            const token = localStorage.getItem(TOKEN);
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['Content-Type'] = 'application/json';
            return config;
        })
        return axiosInstance;
    })
);

const [axiosInstance, axiosAuthInstance] = configureAxiosInstances([
    URL,
    AUTH_URL,
]);

export { axiosInstance, axiosAuthInstance };