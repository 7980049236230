import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getNoDoiArticles, getNoManuscriptIdArticles, postArticlesMerge } from "helpers/articles";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import MDTypography from "components/MDTypography";
import IncompleteArticlesForm from "components/IncompleteArticlesForm";
import { withFormik } from 'formik';
import { shortenTitle, showSuccessToast, showErrorToast, createMarkup } from "helpers/helpers";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function IncompleteArticlesList({ handleSubmit, isSubmitting, status }) {
  const [noDoiArticles, setNoDoiArticles] = useState([]);
  const [noDoiLoading, setNoDoiLoading] = useState(false);
  const [noDoiError, setNoDoiError] = useState(false);
  const [noIdArticles, setNoIdArticles] = useState([]);
  const [noIdLoading, setNoIdLoading] = useState(false);
  const [noIdError, setNoIdError] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDir, setSortDir] = useState("asc");
  const [refreshList, setFefreshList] = useState(false);

  const noDoiColumns = React.useMemo(
    () => [
      {
        Header: 'manuscript id',
        id: 'manuscript_id',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.manuscript_id || "-"}</span>
      },
      {
        Header: 'state',
        id: 'state',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.state || "-"}</span>
      },
      {
        Header: 'title',
        id: 'title',
        Cell: ({ row }) =>
          <span className="route_condition_label"
            dangerouslySetInnerHTML={createMarkup(shortenTitle(row.original.title || "-", 170))}
          />
      },
    ], []
  );

  const noIdColumns = React.useMemo(
    () => [
      {
        Header: 'doi',
        id: 'doi',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.doi || "-"}</span>
      },
      {
        Header: 'state',
        id: 'state',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.state || "-"}</span>
      },
      {
        Header: 'title',
        id: 'title',
        Cell: ({ row }) =>
          <span className="route_condition_label"
            dangerouslySetInnerHTML={createMarkup(shortenTitle(row.original.title || "-", 70))}
          />
      },
    ], []
  );

  useEffect(() => {
    let isMounted = true;
    setNoDoiLoading(true);

    getNoDoiArticles(sortBy, sortDir)
      .then(res => {
        if (isMounted) {
          setNoDoiArticles(res.data.results);
          setNoDoiLoading(false);
        };
      })
      .catch(err => {
        setNoDoiLoading(false);
        setNoDoiError(true);
      })
    return () => { isMounted = false };
  }, [sortBy, sortDir, refreshList]);

  useEffect(() => {
    let isMounted = true;
    setNoIdLoading(true);

    getNoManuscriptIdArticles(sortBy, sortDir)
      .then(res => {
        if (isMounted) {
          setNoIdArticles(res.data.results);
          setNoIdLoading(false);
        };
      })
      .catch(err => {
        setNoIdLoading(false);
        setNoIdError(true);
      })
    return () => { isMounted = false };
  }, [sortBy, sortDir, refreshList]);

  useEffect(() => {
    if (status) {
      setFefreshList(!refreshList);
    }
  }, [status]);


  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar breadcrumbsTitle="Incomplete articles" />
      <MDBox my={3}>
        <IncompleteArticlesForm
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          refreshList={refreshList}
        />
      </MDBox>

      <MDBox mt={3} mb={5}>
        {
          noDoiLoading ?
            <Spinner />
            :
            noDoiError ?
              <ErrorMessage />
              :
              noDoiArticles.length ?
                <Card>
                  <MDBox p={3}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Articles without DOI
                    </MDTypography>
                  </MDBox>

                  <DataTable
                    data={noDoiArticles}
                    columns={noDoiColumns}
                    setSelectedMessage={() => { }}
                    param={"id"}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    setSortBy={setSortBy}
                    setSortDir={setSortDir}
                  />
                </Card>
                :
                <NoContentMessage message={"No articles without DOI"} />
        }
      </MDBox>

      <MDBox my={3}>
        {
          noIdLoading ?
            <Spinner />
            :
            noIdError ?
              <ErrorMessage />
              :
              noIdArticles.length ?
                <Card>
                  <MDBox p={3}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Articles without Manuscript id
                    </MDTypography>
                  </MDBox>

                  <DataTable
                    data={noIdArticles}
                    columns={noIdColumns}
                    setSelectedMessage={() => { }}
                    param={"id"}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    setSortBy={setSortBy}
                    setSortDir={setSortDir}
                  />
                </Card>
                :
                <NoContentMessage message={"No articles without Manuscript id"} />
        }
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
};

const mapPropsToValues = () => ({
  manuscript_id: "",
  doi_id: ""
});

const handleSubmit = (value, { setSubmitting, setStatus, resetForm }) => {
  setSubmitting(true);

  postArticlesMerge(value)
    .then(res => {
      resetForm();
      setStatus(true);
      showSuccessToast("Merged successfully");
    })
    .catch(error => {
      resetForm();
      setStatus(true);
      showErrorToast("Error, not merged");
    });
};


export default withFormik({ handleSubmit, mapPropsToValues })(IncompleteArticlesList);
