export const dashboard = {
  "pipeline": 8,
  "events": [
    {
      "count": 12,
      "week": 36
    },
    {
      "count": 10,
      "week": 35
    }
  ],
  "licenses": [
    {
      "count": 584,
      "license": "non-CC BY"
    },
    {
      "count": 100,
      "license": "CC BY-not specified"
    },
    {
      "count": 175,
      "license": "CC BY-NC-ND"
    },
    {
      "count": 222,
      "license": "CC BY-NC"
    },
    {
      "count": 154,
      "license": "CC BY"
    }
  ],
  "transactions": [
    {
      "count": 10,
      "week": 27
    },
    {
      "count": 11,
      "week": 28
    },
    {
      "count": 13,
      "week": 29
    },
    {
      "count": 12,
      "week": 30
    },
    {
      "count": 14,
      "week": 31
    },
    {
      "count": 15,
      "week": 32
    },
    {
      "count": 17,
      "week": 33
    },
    {
      "count": 18,
      "week": 34
    },
    {
      "count": 17,
      "week": 35
    },
    {
      "count": 20,
      "week": 36
    },
    {
      "count": 22,
      "week": 37
    }
  ],
  "published": [
    {
      "count": 10,
      "week": 27
    },
    {
      "count": 10,
      "week": 28
    },
    {
      "count": 9,
      "week": 29
    },
    {
      "count": 12,
      "week": 30
    },
    {
      "count": 13,
      "week": 31
    },
    {
      "count": 13,
      "week": 32
    },
    {
      "count": 15,
      "week": 33
    },
    {
      "count": 14,
      "week": 34
    },
    {
      "count": 15,
      "week": 35
    },
    {
      "count": 14,
      "week": 36
    },
    {
      "count": 16,
      "week": 37
    }
  ],
  "submitted": [
    {
      "count": 3,
      "week": 27
    },
    {
      "count": 3,
      "week": 28
    },
    {
      "count": 4,
      "week": 29
    },
    {
      "count": 5,
      "week": 30
    },
    {
      "count": 4,
      "week": 31
    },
    {
      "count": 6,
      "week": 32
    },
    {
      "count": 6,
      "week": 33
    },
    {
      "count": 4,
      "week": 34
    },
    {
      "count": 5,
      "week": 35
    },
    {
      "count": 6,
      "week": 36
    },
    {
      "count": 7,
      "week": 37
    }
  ],
  "accepted": [
    {
      "count": 2,
      "week": 27
    },
    {
      "count": 2,
      "week": 28
    },
    {
      "count": 3,
      "week": 29
    },
    {
      "count": 3,
      "week": 30
    },
    {
      "count": 5,
      "week": 31
    },
    {
      "count": 4,
      "week": 32
    },
    {
      "count": 5,
      "week": 33
    },
    {
      "count": 5,
      "week": 34
    },
    {
      "count": 6,
      "week": 35
    },
    {
      "count": 6,
      "week": 36
    },
    {
      "count": 6,
      "week": 37
    }
  ]
}