import { toast } from 'react-toastify';
import { notificationsTime } from '../constants';


export const changeFormatDate = date => {
  return date
    ? [date.getDate(), date.getMonth() + 1, date.getFullYear()].map((date) => (date < 10 ? `0${date}` : date)).join('-')
    : '';
};

export const changeFormatTime = date => {
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  const hours = date.getHours() >= 12 ? date.getHours() - 12 : date.getHours();
  const minutes = ('0' + date.getMinutes()).slice(-2);
  return `${hours}:${minutes} ${ampm}`;
};

export const transformDate = (date, showTime = false) => {
  let res = null;
  if (date) {
    const created = new Date(date);
    res = `${changeFormatDate(created)} ${showTime ? changeFormatTime(created) : ""}`;
  }
  return res;
};

export const parseData = data => {
  if (data && typeof data === 'object') {
    const keys = Object.keys(data);
    const complexItems = [];
    const simpleItems = keys
      .map(objKey => {
        if (typeof data[objKey] !== 'object') {
          return { key: objKey, value: data[objKey] };
        }
        const key = Number.isInteger(Number(objKey)) ? Number(objKey) + 1 : objKey;
        complexItems.push({ key, value: data[objKey] || `${data[objKey]}` });
        return null;
      })
      .filter(item => item);
    return simpleItems.concat(complexItems);
  };
  return null;
};

export const shortenTitle = (title, length) => {
  if (title.length > length) {
    return `${title.substring(0, length)}…`;
  }
  return title;
};

export const getAttribute = (data, attribute) => {
  const name = data.find(attr => attr.Name === attribute);
  return name ? name.Value : "-";
};

export const decodeToken = token => {
  if (token) {
    const base64String = token.split('.')[1];
    return JSON.parse(Buffer.from(base64String, 'base64').toString('ascii'));
  }
  return null;
};

export const showSuccessToast = successMessage => {
  return toast.success(successMessage, {
    position: "top-right",
    autoClose: notificationsTime,
  });
};

export const showErrorToast = errorMessage => {
  return toast.error(errorMessage, {
    position: "top-right",
    autoClose: notificationsTime,
  });
};

export const getCurrentYear = () => new Date().getFullYear();

export const createMarkup = str => {
  return { __html: str };
};

export const filterEvents = (data, showDisregardedEvents) => {
  if (!showDisregardedEvents) {
    return data.filter(item => !item.is_disregarded);
  };
  return data;
};

export const compareObjectsDeep = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  function isObject(object) {
    return object != null && typeof object === 'object';
  };

  if (keys1.length !== keys2.length) {
    return false;
  };

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !compareObjectsDeep(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
};