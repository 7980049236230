import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import DataTable from "components/table/Table";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import Modal from "components/modal/Modal";


function MessagesTabContent({ messages, columns, renderEmpty = false }) {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showDisregardedEvents, setShowDisregardedEvents] = useState(false);

  const findSelectedEvent = selectedMessage => {
    /// renderEmpty has demonstration purpose to render empty details
    if (renderEmpty) {
      return
    }
    return messages.find(item => item.id === selectedMessage);
  };

  useEffect(() => {
    if (selectedMessage) {
      setIsOpen(true);
    };
  }, [selectedMessage]);


  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={false}
        error={false}
        data={findSelectedEvent(selectedMessage)}
        modalTitle={"Event details"}
        showRawData={true}
        size={"xl"}
      />

      {
        messages.length ?
          <Card>
            <DataTable
              data={messages}
              columns={columns}
              setSelectedMessage={setSelectedMessage}
              param={"id"}
              setSortBy={() => { }}
              setSortDir={() => { }}
              hoverable
              cursorPointer
            />
          </Card>
          :
          <NoContentMessage message={"No messages"} />
      }
    </>
  );
};


export default MessagesTabContent;
