import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "components/table/Table";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import MDTypography from "components/MDTypography";
import { getJournalsEvents } from "helpers/journals";
import MDBox from "components/MDBox";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ReactDiffViewer from 'react-diff-viewer';


const ChangelogTabContent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [oldData, setOldData] = useState("");
  const [newData, setNewData] = useState("");

  const сolumns = React.useMemo(
    () => [
      {
        Header: 'type',
        id: 'type',
        Cell: ({ row }) =>
          <MDTypography variant="button" fontWeight="regular">
            {row.index + 1}. {row.original.event || "-"}
          </MDTypography>
      }
    ], []
  );

  const setInitialValues = res => {
    setOldData(res.data[0].article || "");
    setNewData(res.data[0].article || "");
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getJournalsEvents()
      .then(res => {
        if (isMounted) {
          setData(res.data);
          res.data.length && setInitialValues(res);
          setLoading(false);
        };
      })
      .catch(err => {
        setLoading(false);
        setError(true);
      })
    return () => { isMounted = false };
  }, [data]);

  const onSelectEvent = row => {
    const currentItemIndex = data.findIndex(item => (item.article === row.article && item.event === row.event));
    setNewData(row.article || "");

    if (currentItemIndex) {
      setOldData(data[currentItemIndex - 1].article || "");
    } else {
      setOldData(data[0].article || "");
    };
  };


  return (
    <MDBox my={3}>
      {
        loading ?
          <Spinner />
          :
          error ?
            <ErrorMessage />
            :
            <>
              {
                data.length ?
                  <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={12} lg={2}>
                      <Card>
                        <DataTable
                          data={data}
                          columns={сolumns}
                          setSelectedMessage={onSelectEvent}
                          param={"all"}
                          setSortBy={() => { }}
                          setSortDir={() => { }}
                          hoverable
                          cursorPointer
                          hideHeader
                          noBorder
                        />
                      </Card>
                    </Grid>

                    <Grid item xs={12} lg={10}>
                      <MDBox mb={3}>
                        <Card>
                          <MDBox mb={1} p={3} className="diff_viewer_container">
                            <ReactDiffViewer
                              oldValue={oldData}
                              newValue={newData}
                              splitView={false}
                              hideLineNumbers={true}
                              showDiffOnly={false}
                            />
                          </MDBox>
                        </Card>
                      </MDBox>
                    </Grid>
                  </Grid>
                  :
                  <NoContentMessage message={"No events"} />
              }
            </>
      }
    </MDBox >
  );
};


export default ChangelogTabContent;