import React, { useState, useEffect } from "react";
import { getArticle } from "helpers/articles";
import { transformDate } from "helpers/helpers";
import "./Details.css";
import MDBox from "components/MDBox";
import JsonTabContent from "layouts/articles/tabs/JSON";
import EventsTabContent from "layouts/articles/tabs/Events";
import MetadataTabContent from "layouts/articles/tabs/Metadata";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import MDTypography from "components/MDTypography";
import MessagesTabContent from "layouts/articles/tabs/Messages";
import ChangelogTabContent from "layouts/articles/tabs/Changelog";

function Details({ match }) {
  const [article, setArticle] = useState([]);
  const [journal, setJournal] = useState({});
  const [events, setEvents] = useState([]);
  const [lastEvent, setLastEvent] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tabId, setTabId] = useState("metadata");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const filterAuthors = (authors) => {
    return authors
      .map((author) => {
        const authorsWithSameOrcid = authors
          .filter((f_author) => {
            return f_author.orcid && f_author.orcid === author.orcid;
          })
          .sort((a, b) => {
            return JSON.stringify(b).length - JSON.stringify(a).length;
          });
        let biggestSameAuthor = authorsWithSameOrcid[0];
        if (!biggestSameAuthor) biggestSameAuthor = author;
        let res = biggestSameAuthor;
        if (JSON.stringify(author).length < JSON.stringify(biggestSameAuthor).length) res = null;
        return res;
      })
      .filter((e) => !!e)
      .map((e) => JSON.stringify(e))
      .filter(onlyUnique)
      .map((e) => JSON.parse(e));
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    getArticle(match.params.id)
      .then(res => {
        if (isMounted) {
          const filteredEvents = res.data.events.filter(e => e.type !== 'message')
          const sortedEvents = filteredEvents.sort((e1, e2) => new Date(e1.created - e2.created))
          const resArticle = res.data.article
          if (resArticle && resArticle.authors && resArticle.authors.length) {
            resArticle.authors = filterAuthors(resArticle.authors)
          }
          /// types array has demonstration purpose
          const types = ['e1', 'p1'];
          setArticle(resArticle);
          setJournal(res.data.journal);
          // setEvents(filteredEvents);
          setEvents(res.data.events.filter(e => !types.includes(e.type)));
          setLastEvent(sortedEvents.length ? sortedEvents[0] : null);
          // setMessages(res.data.events.filter(e => e.type === 'message'));
          setMessages(res.data.events.filter(e => types.includes(e.type)));
          setLoading(false);
        };
      })
      .catch(error => {
        setLoading(false);
        setError(true);
      })
    return () => { isMounted = false };
  }, [match.params.id]);

  const renderSwitch = tabId => {
    const messagesColumns = React.useMemo(
      () => [
        {
          Header: 'created',
          id: 'created',
          Cell: ({ row }) =>
            <span className="route_condition_label">{transformDate(row.original.created, true)}</span>
        },
        {
          Header: 'type',
          id: 'type',
          Cell: ({ row }) =>
            <span className="route_condition_label">{row.original.type || "-"}</span>
        },
        {
          Header: 'event',
          id: 'event',
          Cell: ({ row }) =>
            <span className="route_condition_label">{row.original.source || "-"}</span>
        },
        {
          Header: 'doi',
          id: 'doi_id',
          Cell: ({ row }) =>
            <span className="route_condition_label">{row.original.doi_id || "-"}</span>
        },
        {
          Header: 'manuscript id',
          id: 'manuscript_id',
          Cell: ({ row }) =>
            <span className="route_condition_label">{row.original.manuscript_id || "-"}</span>
        },
      ], []
    );

    const eventsColumns = React.useMemo(
      () => [
        {
          Header: 'created',
          id: 'created',
          Cell: ({ row }) =>
            <span className="route_condition_label">{transformDate(row.original.created, true)}</span>
        },
        {
          Header: 'type',
          id: 'type',
          Cell: ({ row }) =>
            <span className="route_condition_label">{row.original.type || "-"}</span>
        },
        {
          Header: 'source',
          id: 'source',
          Cell: ({ row }) =>
            <span className="route_condition_label">{row.original.source || "-"}</span>
        },
        {
          Header: 'doi',
          id: 'doi_id',
          Cell: ({ row }) =>
            <span className="route_condition_label">{row.original.doi_id || "-"}</span>
        },
        {
          Header: 'manuscript id',
          id: 'manuscript_id',
          Cell: ({ row }) =>
            <span className="route_condition_label">{row.original.manuscript_id || "-"}</span>
        },
      ], []
    );

    switch (tabId) {
      case 'metadata':
        return (
          <MetadataTabContent
            data={article}
            journal={journal}
          />
        );
      case 'json':
        return (
          <JsonTabContent
            loading={loading}
            error={error}
            article={article}
          />
        );
      case 'eventsList':
        return (
          <EventsTabContent
            data={events}
            columns={eventsColumns}
          />
        );
      case 'changelog':
        return (
          <ChangelogTabContent />
        );
      case 'messages':
        return (
          <MessagesTabContent
            messages={messages}
            columns={messagesColumns}
            renderEmpty
          />
        );
      default:
        return null;
    };
  };

  const breadcrumbsParam = article.doi_id || article.manuscript_id;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabId(event.target.id);
    setTabValue(newValue);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbsTitle={breadcrumbsParam} scrollable />

      <MDBox px={2} mt={1}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Last event: {lastEvent ? lastEvent.type : '-'} / {lastEvent ? transformDate(lastEvent.created) : '-'}
        </MDTypography>
      </MDBox>

      <MDBox mt={1}>
        <Grid container mb={3}>
          <Grid item xs={12} sm={8} lg={4} />
          <AppBar position="static">
            <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
              <Tab label="Metadata" id="metadata" />
              <Tab label="JSON" id="json" />
              <Tab label="Events" id="eventsList" />
              <Tab label="Changelog" id="changelog" />
              <Tab label="Messages" id="messages" />
            </Tabs>
          </AppBar>
        </Grid>

        {renderSwitch(tabId)}

      </MDBox>
    </DashboardLayout >
  );
}

export default Details;
