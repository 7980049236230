export const token = `${process.env.REACT_APP_TOKEN}`;
export const URL = `${process.env.REACT_APP_API_URL}`;
export const AUTH_URL = `${process.env.REACT_APP_AUTH_API_URL}`;

export const authorized = localStorage.getItem(token);
export const itemsPerPage = 50;
export const itemsPerJournalEventsPage = 15;
export const itemsTotal = 4;
export const emptyDataMessage = "No data";
export const emptyDateMessage = "N.A.";
export const notificationsTime = 3000;
export const navTooltipTitle = "This search will query the full set of articles records in the database. It will check fields including title, Manuscript ID, DOI and author list. The current search feature will always search all fields.";
