import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import {
  useMaterialUIController,
  setOpenConfigurator,
} from "context";
import { Autocomplete, Checkbox } from "@mui/material";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import { getJournals } from "helpers/journals";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";


function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    darkMode,
    reportType
  } = controller;
  const [disabled, setDisabled] = useState(false);

  const [sorting, setSorting] = useState("last event first");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scope, setScope] = useState([]);
  const [journals, setJournals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const getStorageID = () => {
    let res = "thiemeSettings"
    if (reportType) {
      res = `${reportType}_settings`
    }
    return res
  }

  const sortingOrderOptions = ["submission date", "acceptance date", "published date", "last event first"];

  useEffect(() => {

    const localSettings = JSON.parse(localStorage.getItem(getStorageID()));

    if (openConfigurator) {
      if (localSettings) {
        setSorting(localSettings.sorting);
        setStartDate(localSettings.startDate);
        setEndDate(localSettings.endDate);
        setScope(localSettings.scope);
      };

      let isMounted = true;
      setLoading(true);

      getJournals()
        .then(res => {
          if (isMounted) {
            setJournals(res.data.results);
            setLoading(false);
          };
        })
        .catch(err => {
          setLoading(false);
          setError(true);
        })
      return () => { isMounted = false };
    };
    return null;
  }, [openConfigurator]);

  const handleSubmit = () => {
    const thiemeSettings = { sorting, startDate, endDate, scope };
    localStorage.setItem(getStorageID(), JSON.stringify(thiemeSettings));
    handleCloseConfigurator();
  };


  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Settings</MDTypography>
          {reportType ? <MDTypography variant="h6">{reportType.split('_').join(' ')} report</MDTypography> : null}
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>
      <Divider />

      <MDBox pb={3} px={3}>
        {reportType !== 'events_per_journal' ? (<MDBox lineHeight={1} mt={2} mb={3}>
          <MDTypography variant="h6" mb={2}>Sorting order</MDTypography>

          <Autocomplete
            value={sorting}
            onChange={(event, newValue) => {
              setSorting(newValue);
            }}
            options={sortingOrderOptions}
            renderInput={(params) => <MDInput {...params} variant="standard" />}
          />
        </MDBox>) : null}
        {reportType !== 'events_per_journal' ? <Divider /> : null}

        <MDBox my={3} lineHeight={1}>
          <MDTypography variant="h6" mb={2}>Timeframe</MDTypography>

          <MDBox sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <MDTypography variant="button" color="text">
              Start-date:
            </MDTypography>
            <MDDatePicker
              value={startDate}
              input={{ placeholder: "Select a date" }}
              onChange={(event, newValue) => {
                setStartDate(newValue);
              }}
            />
          </MDBox>

          <MDBox sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1
          }}>
            <MDTypography variant="button" color="text">
              End-date:
            </MDTypography>
            <MDDatePicker
              value={endDate}
              input={{ placeholder: "Select a date" }}
              onChange={(event, newValue) => {
                setEndDate(newValue);
              }}
            />
          </MDBox>
        </MDBox>
        <Divider />

        {!reportType ? (<MDBox my={3} lineHeight={1}>
          <MDTypography variant="h6" mb={2}>Scope</MDTypography>

          {
            loading ?
              <Spinner />
              :
              error ?
                <ErrorMessage />
                :
                journals.length ?
                  <Autocomplete
                    multiple
                    value={scope}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          style={{ marginRight: 8, marginLeft: -8 }}
                          checked={selected}
                        />
                        {option.title}
                      </li>
                    )}
                    onChange={(event, newValue) => {
                      setScope(newValue);
                    }}
                    options={journals}
                    renderInput={(params) => (
                      <MDInput {...params}
                        variant="standard"
                        placeholder="Type to search"
                      />
                    )}
                  />
                  :
                  <NoContentMessage message={"No journals"} />
          }
        </MDBox>) : null}
        {!reportType ? <Divider /> : null}

        <MDBox my={3} lineHeight={1}>
          <MDButton
            onClick={handleSubmit}
            type="submit"
            color="info"
            variant="contained"
            fullWidth
          >
            save
          </MDButton>
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
};


export default Configurator;
