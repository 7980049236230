import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "./handleErrors";
import { dashboard } from "./mocks/dashboard";

export async function getDashboardData() {
    // return axiosInstance
    //     .get(`tracker/dashboard`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: dashboard });
};
