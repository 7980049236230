import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getEvents } from "helpers/events";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import Pagination from "components/pagination/Pagination";
import { itemsPerPage, itemsTotal } from "../../constants";
import Modal from "../../components/modal/Modal";
import { getArticle } from "helpers/articles";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import { transformDate } from "helpers/helpers";
import QaColumn from "components/QaColumn/QaColumn";

function EventsList() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [sortDir, setSortDir] = useState("asc");
  const [isOpen, setIsOpen] = useState(false);
  const [eventEvents, setEventEvents] = useState([]);
  const [error, setError] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'created',
        id: 'created',
        Cell: ({ row }) =>
          <span className="route_condition_label">{transformDate(row.original.created, true)}</span>
      },
      {
        Header: 'type',
        id: 'type',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.type || "-"}</span>
      },
      {
        Header: 'source',
        id: 'source',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.source || "-"}</span>
      },
      {
        Header: 'qa',
        id: 'qa',
        Cell: ({ row }) => <QaColumn errors={row.original.parsing_errors} />
      },
      {
        Header: 'doi',
        id: 'doi_id',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.doi_id || "-"}</span>
      },
      {
        Header: 'manuscript id',
        id: 'manuscript_id',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.manuscript_id || "-"}</span>
      },
    ], []
  );

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    getEvents(offset, itemsPerPage, sortBy, sortDir)
      .then(res => {
        if (isMounted) {
          setTimeout(() => {
            setEvents(res.data.results.reverse());
            setPageCount(Math.ceil((res.data.total || itemsTotal) / itemsPerPage));
            setLoading(false);
          }, 1000)
        };
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      })
    return () => { isMounted = false };
  }, [offset, sortBy, sortDir]);

  useEffect(() => {
    if (selectedMessage) {
      let isMounted = true;
      setIsOpen(true);
      setModalLoading(true);

      getArticle(selectedMessage)
        .then(res => {
          if (isMounted) {
            setEventEvents(res.data.events);
            setSelectedMessage(null);
            setModalLoading(false);
          };
        })
        .catch(() => {
          setModalLoading(false);
          setModalError(true);
        })
      return () => { isMounted = false };
    };
    return null;
  }, [selectedMessage]);

  const handlePageClick = data => {
    const selectedPage = data.selected;
    const pageItems = selectedPage * itemsPerPage;
    setCurrentPage(selectedPage);
    setOffset(pageItems + 1);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={modalLoading}
        error={modalError}
        data={eventEvents}
        modalTitle={"Events"}
        showRawData={true}
        size={"xl"}
      />

      <MDBox my={3}>
        {
          loading ?
            <Spinner />
            :
            error ?
              <ErrorMessage />
              :
              events.length ?
                <Card>
                  <DataTable
                    data={events}
                    columns={columns}
                    setSelectedMessage={setSelectedMessage}
                    param={"article_id"}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    setSortBy={setSortBy}
                    setSortDir={setSortDir}
                    hoverable
                    cursorPointer
                  />
                  <Pagination
                    handlePageClick={handlePageClick}
                    pageCount={pageCount}
                    currentPage={currentPage}
                  />
                </Card>
                :
                <NoContentMessage message={"No events"} />
        }
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};


export default EventsList;
