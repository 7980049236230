import Card from "@mui/material/Card";
import FormattedJson from "components/FormattedJson/FormattedJson";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


function JsonData({ data, title = null }) {

  return (
    <Card id="events">
      <MDBox p={3}>
        {
          title &&
          <MDTypography variant="h5" fontWeight="medium">
            Events
          </MDTypography>
        }
        <FormattedJson data={data} />
      </MDBox>
    </Card>
  );
};

export default JsonData;
