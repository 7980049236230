export const users = {
    "users": [
        {
            "Username": "1eb51c97-7bde-4d38-a46d-2dea2072c64a",
            "Enabled": true,
            "UserStatus": "CONFIRMED",
            "Attributes": [
                {
                    "Name": "name",
                    "Value": "Sharona Montes"
                },
                {
                    "Name": "email",
                    "Value": "JohnDoe@gmail.com"
                }
            ]
        },
        {
            "Username": "467541f5-b3c0-4ebf-b6ea-9b0d401c7325",
            "Enabled": true,
            "UserStatus": "CONFIRMED",
            "Attributes": [
                {
                    "Name": "name",
                    "Value": "Sherlock Paolillo"
                },
                {
                    "Name": "email",
                    "Value": "JohnDoe@gmail.com"
                }
            ]
        },
        {
            "Username": "c798f365-ebad-4069-8e57-dc4b03af8a78",
            "Enabled": true,
            "UserStatus": "CONFIRMED",
            "Attributes": [
                {
                    "Name": "name",
                    "Value": "Nikolaus Clarke"
                },
                {
                    "Name": "email",
                    "Value": "JohnDoe@gmail.com"
                }
            ]
        },
        {
            "Username": "e5055f6c-a94c-41b9-a8bf-f00d8a0da308",
            "Enabled": true,
            "UserStatus": "CONFIRMED",
            "Attributes": [
                {
                    "Name": "name",
                    "Value": "Adoree Berrisford"
                },
                {
                    "Name": "email",
                    "Value": "JohnDoe@gmail.com"
                }
            ]
        },
        {
            "Username": "e70b0be4-5420-448d-b745-b601b9fa52b8",
            "Enabled": true,
            "UserStatus": "CONFIRMED",
            "Attributes": [
                {
                    "Name": "name",
                    "Value": "Osgood Millhouse"
                },
                {
                    "Name": "email",
                    "Value": "JohnDoe@gmail.com"
                }
            ]
        },
        {
            "Username": "e70b0be4-5420-448d-b745-b601b9fa52b8",
            "Enabled": true,
            "UserStatus": "CONFIRMED",
            "Attributes": [
                {
                    "Name": "name",
                    "Value": "Dalia Bushen"
                },
                {
                    "Name": "email",
                    "Value": "JohnDoe@gmail.com"
                }
            ]
        },
    ]
}
