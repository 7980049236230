import React, { useState } from "react";
import MDButton from "components/MDButton";
import "./Table.css";
import MDBox from "components/MDBox";
import { token, URL } from "../../constants";
import Modal from "components/modal/Modal";


const ArticlesExport = ({ onExportArticles, exportFormat, setExportFormat }) => {
    const sidenavTypeActiveButtonStyles = ({
        functions: { pxToRem, linearGradient },
        palette: { white, gradients, background },
    }) => ({
        height: pxToRem(39),
        marginX: pxToRem(4),
        background: linearGradient(gradients.dark.main, gradients.dark.state),
        color: white.main,

        "&:hover, &:focus, &:focus:not(:hover)": {
            background: linearGradient(gradients.dark.main, gradients.dark.state),
            color: white.main,
        },
    });

    const sidenavTypeButtonsStyles = ({
        functions: { pxToRem },
        palette: { white, dark, background },
        borders: { borderWidth },
    }) => ({
        height: pxToRem(39),
        marginX: pxToRem(4),
        background: white.main,
        color: dark.main,
        border: `${borderWidth[1]} solid ${dark.main}`,

        "&:hover, &:focus, &:focus:not(:hover)": {
            background: white.main,
            color: dark.main,
            border: `${borderWidth[1]} solid ${dark.main}`,
        },
    });


    return (
        <MDBox my={3} mx={"auto"}>
            <MDBox sx={{ display: "flex", mb: 4 }}>
                <MDButton
                    variant="gradient"
                    value="json"
                    onClick={e => { setExportFormat(e.target.value) }}
                    fullWidth
                    sx={exportFormat === "json" ? sidenavTypeActiveButtonStyles : sidenavTypeButtonsStyles}
                >
                    JSON
                </MDButton>

                <MDButton
                    variant="gradient"
                    value="exel"
                    onClick={e => { setExportFormat(e.target.value) }}
                    fullWidth
                    sx={exportFormat === "exel" ? sidenavTypeActiveButtonStyles : sidenavTypeButtonsStyles}
                >
                    Excel
                </MDButton>
            </MDBox>

            <MDButton
                variant="gradient"
                color="info"
                size="medium"
                fullWidth
                onClick={onExportArticles}
            >
                export
            </MDButton>
        </MDBox>
    );
};


const ExportPanel = ({ articlesType, setLoading, setError, articlesCount }) => {
    const localSettings = JSON.parse(localStorage.getItem("thiemeSettings"));

    const [isOpen, setIsOpen] = useState(false);
    const [exportFormat, setExportFormat] = useState(localSettings?.exportFormat || "json");

    const sorting = localSettings?.sorting || "last event first";
    const startDate = localSettings?.startDate || "";
    const endDate = localSettings?.endDate || "";
    const scope = localSettings?.scope || "";

    const getDateNow = () => {
        const date = new Date();
        return [
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            date.getHours(),
            date.getMinutes()
        ].join('_');
    };

    const converBase64toBlob = (content, contentType) => {
        contentType = contentType || '';
        const sliceSize = 512;
        const byteCharacters = window.atob(
            content);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset +=
            sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i += 1) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, {
            type: contentType
        });
        return blob;
    };

    const downloadFile = (file, fileName) => {
        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setLoading(false);
    };

    const exportReport = async (articlesType, type) => {
        const articlesUrl = articlesType === "allArticles" ? "tracker/search" : "admin/articles/withoutpid";

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem(token)}`,
        };
        if (type !== 'json') {
            headers['Accept'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        };

        const filteredScope = encodeURIComponent(JSON.stringify(scope));
        const filteredSorting = encodeURIComponent(JSON.stringify(sorting));

        setLoading(true);
        const res = fetch(`${URL}${articlesUrl}?sorting=${filteredSorting}&startDate=${startDate}&endDate=${endDate}&scope=${filteredScope}&format=${type}`, {
            method: 'GET',
            headers
        })
        if (type !== 'json') {
            res.then(res => {
                return res.text()
            })
                .then(base64Text => {
                    const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    const ext = 'xlsx';
                    const fileName = `articles_export_${getDateNow()}.${ext}`;
                    const blob = converBase64toBlob(base64Text, mimeType)
                    const file = new File([blob],
                        fileName,
                        { type: mimeType }
                    );
                    downloadFile(file, fileName)
                })
                .catch(err => {
                    setLoading(false);
                    setError(true);
                });
        } else {
            res.then(res => res.blob())
                .then(blob => {
                    const mimeType = 'application/json';
                    const ext = 'txt';
                    const fileName = `articles_export_${getDateNow()}.${ext}`;
                    const file = new File([blob],
                        fileName,
                        { type: mimeType }
                    );
                    downloadFile(file, fileName)
                })
                .catch(err => {
                    setLoading(false);
                    setError(true);
                });
        }
    };

    const onSelectFormat = () => {
        setIsOpen(true);
    };

    const onExportArticles = () => {
        if (localSettings) {
            localStorage.setItem("thiemeSettings", JSON.stringify({ ...localSettings, exportFormat }));
        } else {
            localStorage.setItem("thiemeSettings", JSON.stringify({ exportFormat }));
        };

        exportReport(articlesType, exportFormat);
    };


    return (
        <>
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                loading={false}
                error={false}
                data={"userDetails"}
                modalTitle={"Export format"}
                content={
                    <ArticlesExport
                        exportFormat={exportFormat}
                        setExportFormat={setExportFormat}
                        onExportArticles={onExportArticles}
                    />
                }
                size={"xs"}
                hideButtons={true}
            />

            <MDBox className="search_panel">
                {articlesCount ? <p className="count_value">{articlesCount} articles</p> : null}
                <MDButton
                    className="search_button"
                    variant="gradient"
                    color="info"
                    size="medium"
                    onClick={onSelectFormat}
                >
                    export
                </MDButton>
            </MDBox>
        </>
    );
};


export default ExportPanel;

