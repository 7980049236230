import { token } from "../constants";
import { axiosAuthInstance } from "./axiosSetup";

export async function logIn(value) {
    return axiosAuthInstance
        .post('/authorize', value)
        .catch(error => console.log(error))
};

export const logOut = () => {
    localStorage.removeItem(token);
    window.location.reload();
};