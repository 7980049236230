import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getCurrentYear } from "helpers/helpers";


const ArticleCountPanel = ({ title, count, percentage }) => { 
    const renderTitle = title => {
        if (typeof (title) === "number") {
            return `Articles published in ${title} ${title === getCurrentYear() ? "YTD" : ""}`;
        }
        return title;
    };


    return (
      <MDBox p={2}>
        <MDBox mb={0.5} lineHeight={1}>
          <MDTypography
            variant="button"
            fontWeight="medium"
            color="text"
            textTransform="capitalize"
          >
            {renderTitle(title)}
          </MDTypography>
        </MDBox>

        <MDTypography variant="h5" fontWeight="bold">
          {count}
          {(percentage || percentage === 0) && (
            <MDTypography
              variant="button"
              fontWeight="bold"
              color={percentage >= 0 ? "success" : "error"}
            >
              &nbsp;{`(${percentage >= 0 ? "+" + percentage : percentage}%)`}
            </MDTypography>
          )}
        </MDTypography>
      </MDBox>
    );
};


export default ArticleCountPanel;

