import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "./handleErrors";
import { article, articles, noManuscriptIdArticles, noPidArticles, noDoiArticles, articlesForInstitution, dataForInstitution, articlesForConsortia, dataForConsortia, eventsArticles } from "./mocks/articles";

export async function getArticles(searchValue, offset, perPage, sortBy, sortDir, sorting, startDate, endDate, scope) {
    // return axiosInstance
    //     .get(`tracker/search?query=${searchValue}&startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&sorting=${sorting}&startDate=${startDate}&endDate=${endDate}&scope=${scope}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: articles });
};

export async function getArticle(id) {
    // return axiosInstance
    //     .get(`tracker/article/${id}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: article });
};

export async function getEventArticle(id) {
    // return axiosInstance
    //     .get(`tracker/article/${id}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: eventsArticles.find(item => item.id === id) || {} });
};

export async function getNoPidArticles(offset, perPage, sortBy, sortDir, sorting, startDate, endDate, scope) {
    // return axiosInstance
    //     .get(`admin/articles/withoutpid?startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&sorting=${sorting}&startDate=${startDate}&endDate=${endDate}&scope=${scope}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: noPidArticles });
};

export async function getNoManuscriptIdArticles(sortBy, sortDir) {
    // return axiosInstance
    //     .get(`admin/articles/withoutmanuscriptid?orderby=${sortBy}&orderdir=${sortDir}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: noManuscriptIdArticles });
};

export async function getNoDoiArticles(sortBy, sortDir) {
    // return axiosInstance
    //     .get(`admin/articles/withoutdoi?orderby=${sortBy}&orderdir=${sortDir}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: noDoiArticles });
};

export async function getArticlesForInstitution(id, offset, perPage, sortBy, sortDir) {
    // return axiosInstance
    //     .get(`tracker/articles/pid?ror=${id}&startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: articlesForInstitution });
};

export async function getDataForInstitution(id) {
    // return axiosInstance
    //     .get(`tracker/reports/institution?pid=${id}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: dataForInstitution });
};

export async function getArticlesForConsortia(name, offset, perPage, sortBy, sortDir, sorting, startDate, endDate, scope) {
    // return axiosInstance
    //     .get(`tracker/articles/consortia/${name}?startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&sorting=${sorting}&startDate=${startDate}&endDate=${endDate}&scope=${scope}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: articlesForConsortia });
};

export async function getDataForConsortia(name) {
    // return axiosInstance
    //     .get(`tracker/reports/consortia?name=${name}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: dataForConsortia });
};

export async function postArticlesMerge(data) {
    // return axiosInstance
    // .post(`admin/articles/linkidentifiers`, data)
    // .catch(handleErrors)
};

