import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import { getJournals, getJournal } from "helpers/journals";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import Modal from "components/modal/Modal";
import { shortenTitle } from "helpers/helpers";


function JournalsList() {
  const [journals, setJournals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [error, setError] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [journalDetails, setJournalDetails] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDir, setSortDir] = useState("asc");

  const columns = React.useMemo(
    () => [
      {
        Header: 'title',
        id: 'title',
        Cell: ({ row }) =>
          <span className="route_condition_label">{shortenTitle(row.original.title, 170) || "-"}</span>
      },
      {
        Header: 'doi',
        id: 'doi',
        Cell: ({ row }) =>
          <span className="route_condition_label">{row.original.doi || "-"}</span>
      },
    ], []
  );

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    getJournals(sortBy, sortDir)
      .then(res => {
        if (isMounted) {
          setJournals(res.data.results);
          setLoading(false);
        };
      })
      .catch(err => {
        setLoading(false);
        setError(true);
      })
    return () => { isMounted = false };
  }, [sortBy, sortDir]);

  useEffect(() => {
    if (selectedMessage) {
      let isMounted = true;
      setIsOpen(true);
      setModalLoading(true);

      getJournal(selectedMessage)
        .then(res => {
          if (isMounted) {
            setJournalDetails(res.data.journal);
            setSelectedMessage(null);
            setModalLoading(false);
          };
        })
        .catch(error => {
          setModalLoading(false);
          setModalError(true);
        })
      return () => { isMounted = false };
    };
    return null;
  }, [selectedMessage]);


  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={modalLoading}
        error={modalError}
        data={journalDetails}
        modalTitle={"Journal details"}
      />

      <MDBox my={3}>
        {
          loading ?
            <Spinner />
            :
            error ?
              <ErrorMessage />
              :
              journals.length ?
                <Card>
                  <DataTable
                    data={journals}
                    columns={columns}
                    setSelectedMessage={setSelectedMessage}
                    param={"id"}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    setSortBy={setSortBy}
                    setSortDir={setSortDir}
                    hoverable
                    cursorPointer
                  />
                </Card>
                :
                <NoContentMessage message={"No journals"} />
        }
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};


export default JournalsList;
