import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import { withFormik } from 'formik';
import LoginForm from "components/LoginForm";
import { logIn } from "helpers/auth";
import Spinner from "components/Spinner/Spinner";
import { token } from "../../../constants";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from "helpers/helpers";


function Login({ handleSubmit, isSubmitting }) {

  return (
    <CoverLayout image={bgImage}>
      <ToastContainer />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Login
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {
            isSubmitting ?
              <Spinner />
              :
              <LoginForm handleSubmit={handleSubmit} />
          }
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

const mapPropsToValues = () => ({
  email: "",
  password: "",
});

const handleSubmit = (value, { props, setSubmitting }) => {
  logIn(value)
    .then(response => {
      localStorage.setItem(token, response.data.token);
      props.history.push("/dashboard");
      window.location.reload();
    })
    .catch(error => {
      setSubmitting(false);
      showErrorToast("User does not exist");
    })
};

export default withFormik({ handleSubmit, mapPropsToValues })(Login);