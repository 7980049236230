import Report from "layouts/reports";
import Login from "layouts/authentication/log-in/Login";
import ArticlesList from "layouts/articles/ArticlesList";
import EventsList from "layouts/events/EventsList";
import Workflow from "layouts/workflows";
import { Switch, Route, Redirect } from 'react-router-dom';
import JournalsList from "layouts/journals/JournalsList";
import { token } from "./constants";
import Details from "layouts/pages/details/Details";
import NoPidArticlesList from "layouts/articles/NoPidArticlesList";
import IncompleteArticlesList from "layouts/articles/IncompleteArticlesList";
import UsersList from "layouts/users/UsersList";
import Account from "layouts/account/Account";
import ArticlesPerInstitutionList from "layouts/articles/ArticlesPerInstitutionList";
import EventsPerJournalList from "layouts/events/EventsPerJournalList";
import ArticlesForConsortiaList from "layouts/articles/ArticlesForConsortiaList";
import Dashboard from "layouts/dashboards/sales/Dashboard";

const PrivateRoute = props => {
    const authorized = localStorage.getItem(token);

    return authorized ?
        <Route {...props} />
        :
        <Redirect to="/login" />
};

const Routes = () => (
    <Switch>
        <Route path="/login" component={Login} />
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/report/:id" component={Report} />
        <PrivateRoute path="/workflow/:id" component={Workflow} />
        <PrivateRoute path="/users" component={UsersList} />
        <PrivateRoute path="/journals" component={JournalsList} />
        <PrivateRoute path="/events" component={EventsList} />
        <PrivateRoute path="/events-per-journal" component={EventsPerJournalList} />
        <PrivateRoute exact path="/articles" component={ArticlesList} />
        <PrivateRoute path="/articles/:id" component={Details} />
        <PrivateRoute path="/no-pid-articles" component={NoPidArticlesList} />
        <PrivateRoute path="/articles-per-institution" component={ArticlesPerInstitutionList} />
        <PrivateRoute path="/incomplete-articles" component={IncompleteArticlesList} />
        <PrivateRoute path="/articles-for-consortia" component={ArticlesForConsortiaList} />
        <PrivateRoute path="/account" component={Account} />
    </Switch>
);

export default Routes;